import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type * as ReactTable from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import React, { useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import type {
  FetchProductsResponse,
  Product,
} from '@jane/ad-manager/data-access';
import {
  DEFAULT_PRODUCTS_PER_PAGE,
  useFetchProductFilters,
  useFetchProducts,
} from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import {
  CheckboxField,
  Flex,
  Link,
  SortUpIcon,
  Typography,
} from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { ProductImage } from './productImage';
import type { ProductTableWithMeta } from './productTable';

type FilterValues = string[] | undefined;

const SortableHeader = ({
  column,
  children,
}: ReactTable.HeaderContext<Product, string> & {
  children: React.ReactNode;
}) => (
  <Link variant="minimal" onClick={column.getToggleSortingHandler()}>
    <Flex>
      {children}
      {column.getIsSorted() && (
        <SortUpIcon rotate={column.getIsSorted() === 'asc' ? 'up' : 'down'} />
      )}
    </Flex>
  </Link>
);

const formatPriceWeightSummary = ({
  lowest_price,
  highest_price,
  available_weight_count,
}: {
  available_weight_count: number | null;
  highest_price: number | null;
  lowest_price: number | null;
}) => {
  if (!available_weight_count) return '';

  if (available_weight_count === 1)
    return `${formatCurrency(lowest_price)}/1 weight`;

  return `${formatCurrency(lowest_price)}-${formatCurrency(
    highest_price
  )}/${available_weight_count} weights`;
};

export const useProductTable = (
  savedProductIds: string[],
  currentProductIds?: string[]
): ProductTableWithMeta => {
  const [columnFilters, setColumnFilters] =
    useState<ReactTable.ColumnFiltersState>([]);
  const { selectedBrand } = useSelectedBrandContext();
  const [sorting, setSorting] = useState<ReactTable.SortingState>([
    {
      desc: false,
      id: 'name',
    },
  ]);

  const nameFilter = columnFilters.find((filter) => filter.id === 'name')
    ?.value as string;
  const productIds = currentProductIds || savedProductIds;
  const idsFilter =
    productIds.length > 0 && !nameFilter ? productIds : undefined;

  const productsQuery = useFetchProducts({
    brandId: selectedBrand?.id,
    order: sorting[0],
    ids: idsFilter,
    name: nameFilter,
    kinds: columnFilters.find((filter) => filter.id === 'kind')
      ?.value as FilterValues,
    categories: columnFilters.find((filter) => filter.id === 'category')
      ?.value as FilterValues,
    root_subtypes: columnFilters.find((filter) => filter.id === 'root_subtype')
      ?.value as FilterValues,
    per_page: idsFilter
      ? Math.max(productIds.length, DEFAULT_PRODUCTS_PER_PAGE)
      : DEFAULT_PRODUCTS_PER_PAGE,
  });
  const {
    error: productsQueryError,
    data: productsQueryData,
    isLoading: productsQueryIsLoading,
  } = productsQuery;

  const filtersQuery = useFetchProductFilters({ brandId: selectedBrand?.id });
  const { data: filtersQueryData, isLoading: filtersQueryIsLoading } =
    filtersQuery;

  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView) productsQuery.fetchNextPage();
    },
  });

  const rowData = useMemo(() => {
    if (productsQueryError || !productsQueryData || productsQueryIsLoading)
      return [];

    return productsQueryData.pages.reduce(
      (pages, page) => [...pages, ...page.products],
      [] as FetchProductsResponse['products']
    );
  }, [productsQueryData, productsQueryError, productsQueryIsLoading]);

  const columnHelper = createColumnHelper<Product>();
  const columns = useMemo<ReactTable.ColumnDef<Product, string>[]>(() => {
    const selectColumn = columnHelper.display({
      id: 'select',
      header: ({ table }) => {
        return (
          <CheckboxField
            {...{
              label: 'select-all-box',
              labelHidden: true,
              name: 'select-all-box',
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomePageRowsSelected(),
              onClick: table.getToggleAllPageRowsSelectedHandler(),
            }}
          />
        );
      },
      cell: ({ row }) => {
        return (
          <CheckboxField
            {...{
              label: `select-row-${row.id}`,
              labelHidden: true,
              name: `select-row-${row.id}`,
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onClick: row.getToggleSelectedHandler(),
            }}
          />
        );
      },
      size: 72,
    });

    const nameColumn = columnHelper.accessor('name', {
      header: (props) => (
        <SortableHeader {...props}>
          <Typography>
            {(props.table as ProductTableWithMeta).options.meta?.total} PRODUCTS
          </Typography>
        </SortableHeader>
      ),
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <Flex alignItems="center" gap={16}>
            <ProductImage product={row.original} />
            <Typography>{row.original.name}</Typography>
          </Flex>
        );
      },
      size: 244,
    });

    const kindColumn = columnHelper.accessor((row) => capitalize(row.kind), {
      id: 'kind',
      header: (props) => (
        <SortableHeader {...props}>
          <Typography color="grays-mid">CATEGORY</Typography>
        </SortableHeader>
      ),
      meta: {
        filterValues: filtersQueryData?.filters.kinds,
        filterLabel: 'Category',
      },
    });

    const rootSubtypeColumn = columnHelper.accessor(
      (row) => (row.root_subtype ? capitalize(row.root_subtype) : ''),
      {
        id: 'root_subtype',
        header: (props) => (
          <SortableHeader {...props}>
            <Typography color="grays-mid">SUBCATEGORY</Typography>
          </SortableHeader>
        ),
        meta: {
          filterValues: filtersQueryData?.filters.root_subtypes,
          filterLabel: 'Subcategory',
        },
      }
    );

    const priceWeightsSummaryColumn = columnHelper.accessor(
      ({ lowest_price, highest_price, available_weights }) =>
        formatPriceWeightSummary({
          lowest_price,
          highest_price,
          available_weight_count: available_weights.length,
        }),
      {
        id: 'price_weights_summary',
        header: (props) => (
          <Typography color="grays-mid">PRICE/WEIGHT</Typography>
        ),
        enableColumnFilter: false,
        enableSorting: false,
      }
    );

    const categoryColumn = columnHelper.accessor(
      (row) => (row.category ? capitalize(row.category) : ''),
      {
        id: 'category',
        header: (props) => (
          <SortableHeader {...props}>
            <Typography color="grays-mid">LINEAGE</Typography>
          </SortableHeader>
        ),
        meta: {
          filterValues: filtersQueryData?.filters.categories,
          filterLabel: 'Lineage',
        },
      }
    );

    return [
      selectColumn,
      nameColumn,
      kindColumn,
      rootSubtypeColumn,
      priceWeightsSummaryColumn,
      categoryColumn,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersQueryData, filtersQueryIsLoading]);

  return useReactTable({
    columns,
    defaultColumn: {
      size: 200,
    },
    meta: {
      fetchMoreRef,
      total: productsQuery.data?.pages[0].meta.total,
    },
    state: { columnFilters, sorting },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    data: rowData,
    getRowId: (rowData) => rowData.id.toString(),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });
};
