import styled from '@emotion/styled';
import { useState } from 'react';
import { useController } from 'react-hook-form';

import { Accordion } from '@jane/shared/components';
import { Flex, RadioFieldGroup, Typography } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';
import { DailySchedule } from './dailySchedule';

const AccordionHeading = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

const spendAllocationOptions = [
  {
    label: 'Consistent schedule throughout week',
    id: 'consistent',
    value: 'consistent',
  },
  {
    label: 'Manually select days',
    id: 'manual',
    value: 'manual',
  },
];

export const SpendAllocation = () => {
  const [showDailySchedule, setShowDailySchedule] = useState(false);
  const {
    field: { onChange: onChangeDayParting, value: dayParting },
  } = useController<FlatAdSubmissionForm, 'dayParting'>({
    name: 'dayParting',
  });

  const defaultChecked =
    Object.keys(dayParting).length < 1 ? 'consistent' : 'automatic';

  const selectedAllocationText = showDailySchedule
    ? 'Manually select days'
    : 'Consistent schedule throughout week';

  return (
    <Accordion>
      <Accordion.Item id="ad-spend-allocation">
        <Accordion.Heading>
          <AccordionHeading>
            <Flex justifyContent="space-between">
              <Typography variant="body-bold">
                How do you want to allocate your spend?
              </Typography>
              <Accordion.Indicator size={18} />
            </Flex>
            <Typography variant="body">{selectedAllocationText}</Typography>
          </AccordionHeading>
        </Accordion.Heading>
        <Accordion.Content>
          <RadioFieldGroup
            name="_spendAllocationMode"
            options={spendAllocationOptions}
            mt={32}
            defaultChecked={defaultChecked}
            onChange={(option) => {
              if (option === 'manual') {
                setShowDailySchedule(true);
              } else {
                onChangeDayParting({});
                setShowDailySchedule(false);
              }
            }}
          />
          {showDailySchedule && <DailySchedule />}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
};
