import { z } from 'zod';

const PhotoSchema = z.object({
  id: z.string().url(),
  position: z.number(),
  urls: z.object({
    extraLarge: z.string(),
    medium: z.string(),
    original: z.string(),
    small: z.string(),
  }),
});

export const ProductSchema = z.object({
  id: z.number(),
  name: z.string(),
  brand: z.string(),
  category: z.string().nullable(),
  root_subtype: z.string().nullable(),
  kind: z.string(),
  photos: z.array(PhotoSchema),
  highest_price: z.number().nullable(),
  lowest_price: z.number().nullable(),
  available_weights: z.array(z.string()),
});

export const FetchProductsResponseSchema = z.object({
  products: z.array(ProductSchema),
  meta: z.object({
    page: z.number(),
    page_total: z.number(),
    per_page: z.number(),
    total: z.number(),
    number_of_pages: z.number(),
  }),
});

export const FetchProductFiltersResponseSchema = z.object({
  filters: z.object({
    kinds: z.array(z.string()),
    categories: z.array(z.string()),
    root_subtypes: z.array(z.string()),
  }),
});

export type FetchProductsResponse = z.infer<typeof FetchProductsResponseSchema>;

export type FetchProductFiltersResponse = z.infer<
  typeof FetchProductFiltersResponseSchema
>;

export type Product = z.infer<typeof ProductSchema>;
