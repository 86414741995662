import type * as ReactTable from '@tanstack/react-table';
import React from 'react';

import type { Product } from '@jane/ad-manager/data-access';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { Box, Button, Flex, Modal, Typography } from '@jane/shared/reefer';

import { ProductTable } from './productTable';

export const ProductTableModal = ({
  onDiscardChanges,
  open,
  table,
  onSave,
  hasChanges,
}: {
  hasChanges: boolean;
  onDiscardChanges: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  open: boolean;
  table: ReactTable.Table<Product>;
}) => {
  return (
    <ConfirmDiscardWrapper
      setOpen={onDiscardChanges}
      open={open}
      variant="flex"
      hasChanges={hasChanges}
    >
      <>
        <Modal.Header
          data-testid="product-selector-modal"
          title="Select Products"
          actions={<Button label="Save" onClick={() => onSave()} />}
        />
        <Modal.Content padding={false}>
          <ProductTable table={table} />
        </Modal.Content>
        <Modal.Footer>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Typography color="grays-mid">
                {Object.keys(table.getState().rowSelection).length} products
                selected
              </Typography>
            </Box>
            <Flex gap={16}>
              <Button
                label="Select All"
                variant="tertiary"
                onClick={() => table.toggleAllRowsSelected(true)}
              />
              <Button
                label="Select none"
                variant="tertiary"
                onClick={() => table.toggleAllRowsSelected(false)}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </>
    </ConfirmDiscardWrapper>
  );
};
