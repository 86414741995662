import type * as ReactTable from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  type ApiFlightSummary,
  useGetBrandAds,
} from '@jane/ad-manager/data-access';
import { DEFAULT_START_DATE, useFilterParams } from '@jane/ad-manager/hooks';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import {
  formatPacificTimeToUtc,
  formatPacificTimeToUtcEod,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import { Box, Flex, useToast } from '@jane/shared/reefer';

import { BrandSelectHeader } from '../header';
import { FlightEmptyState } from './FlightEmptyState';
import { AdActions } from './adActions';
import { AdBuilderModal } from './adBuilder/adBuilderModal';
import type {
  AdPlacement,
  FlatAdSubmissionForm,
} from './adBuilder/useAdBuilderForm';
import {
  buildDuplicatedFormDataWithDefaults,
  platformsFromDisplaysOnProp,
  productCategoriesFromKeywords,
  useAdBuilderForm,
} from './adBuilder/useAdBuilderForm';
import { FlightsTable } from './flightsTable';
import { ColumnVisibilityPopover } from './flightsTable/columnVisibilityPopover';
import { useFlightsTable } from './flightsTable/useFlightsTable';
import { StatisticsDisplay } from './statisticsDisplay';
import { StatisticsFilters } from './statisticsFilters';

export interface DigitalMerchandisingNavigationState {
  adBuilderPrefill?: FlatAdSubmissionForm;
}
export const useNavigateToDigitalMerchandising = () => {
  const navigate = useNavigate();
  return (state: DigitalMerchandisingNavigationState) => {
    navigate(brandsPaths.digitalMerchandising(), { state });
  };
};

export const DigitalMerchandising = () => {
  const {
    state: locationState,
  }: { state: DigitalMerchandisingNavigationState | null } = useLocation();

  const [isAdBuilderOpen, setIsAdBuilderOpen] = useState<boolean>(
    !!locationState?.adBuilderPrefill
  );

  const { selectedBrand, setSelectedBrand } = useSelectedBrandContext();

  const { filterParams, onChangeFilterParams } = useFilterParams();

  const {
    data: brandAdsData,
    isLoading: isAdsLoading,
    refetch: refetchBrandAds,
    error: fetchBrandAdsError,
  } = useGetBrandAds({
    brandId: selectedBrand?.id,
    startDate:
      filterParams.startDatePT &&
      formatPacificTimeToUtc(filterParams.startDatePT),
    endDate:
      filterParams.endDatePT &&
      formatPacificTimeToUtcEod(filterParams.endDatePT),
    models: filterParams.types,
    states: filterParams.locations,
  });

  const toast = useToast();
  const adBuilderForm = useAdBuilderForm({
    onError: () =>
      toast.add({ label: 'Failed to create flight', variant: 'error' }),
    onSuccess: () => {
      setIsAdBuilderOpen(false);
      toast.add({ label: 'Flight created successfully' });
      refetchBrandAds();
    },
  });

  const {
    formMethods: { reset: resetForm },
  } = adBuilderForm;

  useEffect(() => {
    if (!locationState?.adBuilderPrefill) {
      return;
    }

    resetForm(locationState.adBuilderPrefill);
    setIsAdBuilderOpen(true);
  }, [resetForm, locationState]);

  const formDataFromSummary = (
    summary: ApiFlightSummary
  ): Partial<FlatAdSubmissionForm> => {
    // we don't have a way of displaying "Mixed" in the UI currently so we filter it out
    const adPlacement = summary.zones.filter(
      (z) => z !== 'Mixed'
    ) as AdPlacement;

    const isPriority = summary.model === 'flat';
    const adType = isPriority ? 'cpm' : summary.model;
    const budget = isPriority ? summary.bid : summary.dailyBudget;

    const form: Partial<FlatAdSubmissionForm> = {
      adPlacement,
      adType,
      bid: summary.bid || undefined,
      budget: budget || 0,
      displayPlatforms: platformsFromDisplaysOnProp(summary.displaysOn),
      endDate: summary.endDate
        ? dayjs(summary.endDate).format('YYYY-MM-DD')
        : undefined,
      impressions: summary.frequencyCapNumerator || undefined,
      isPriority,
      menuRowTitle: summary.title || selectedBrand?.name,
      productCategories: productCategoriesFromKeywords(summary.keywords),
      startDate: dayjs(summary.startDate).format('YYYY-MM-DD'),
      states: summary.states,
      excludedStoreIds: summary.excludedStores.map((store) =>
        store.id.toString()
      ),
      timePeriod: summary.frequencyCapDenominator || undefined,
      timeUnit: summary.frequencyCapUnit,
      storeIds: summary.stores.map((store) => store.id.toString()),
    };

    return form;
  };

  const onDuplicateRow = ({ original }: ReactTable.Row<ApiFlightSummary>) => {
    const formData = formDataFromSummary(original);
    const duplicatedForm = buildDuplicatedFormDataWithDefaults(formData);
    resetForm(duplicatedForm);
    setIsAdBuilderOpen(true);
  };

  const flightsTable = useFlightsTable({
    data: brandAdsData?.flights.sort((a, b) => b.id - a.id) || [],
    onDuplicateRow,
    onFlightUpdated: () => refetchBrandAds(),
  });

  const formatToUSD = (num: number): string => {
    return `${num.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const brandAdsStatistics = brandAdsData?.statistics
    ? [
        {
          label: 'ROAS',
          valueDisplay: formatToUSD(brandAdsData.statistics.roas),
        },
        {
          label: 'Ads live',
          valueDisplay: brandAdsData.statistics.adsLive.toLocaleString(),
        },
        {
          label: 'Customers impressed',
          valueDisplay:
            brandAdsData.statistics.customersImpressed.toLocaleString(),
        },
        {
          label: 'Total billable',
          valueDisplay: formatToUSD(brandAdsData.statistics.totalBillable),
        },
      ]
    : [
        { label: 'ROAS', valueDisplay: 0 },
        { label: 'Ads live', valueDisplay: 0 },
        {
          label: 'Customers impressed',
          valueDisplay: 0,
        },
        {
          label: 'Total billable',
          valueDisplay: 0,
        },
      ];

  const isEmpty = !isAdsLoading && !brandAdsData?.flights.length; // No results or have issues loading flights
  const onEmptyFlightClick = (isError: boolean) => {
    if (isError) {
      // Re-trigger query
      refetchBrandAds();
    }

    // Reset filters and get default view results
    onChangeFilterParams({
      locations: [],
      types: [],
      startDatePT: DEFAULT_START_DATE,
      endDatePT: null,
    });
  };

  return (
    <Box>
      {selectedBrand && (
        <Box>
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
          />
          <Flex justifyContent="space-between" px={24} pt={24}>
            <StatisticsFilters
              filterParams={filterParams}
              onChangeFilterParams={onChangeFilterParams}
            />
            <Flex gap={12}>
              <ColumnVisibilityPopover flightsTable={flightsTable} />
              <AdActions openAdBuilder={setIsAdBuilderOpen} />
            </Flex>
          </Flex>
          {isEmpty ? (
            <FlightEmptyState
              isError={!!fetchBrandAdsError}
              onClick={onEmptyFlightClick}
            />
          ) : (
            <>
              <Flex mt={24} px={24}>
                <StatisticsDisplay entries={brandAdsStatistics} />
              </Flex>
              <Flex>
                <FlightsTable
                  flightsTable={flightsTable}
                  isLoading={isAdsLoading}
                />
              </Flex>
            </>
          )}
        </Box>
      )}
      <AdBuilderModal
        isOpen={isAdBuilderOpen}
        setIsOpen={setIsAdBuilderOpen}
        adBuilderForm={adBuilderForm}
      />
    </Box>
  );
};
