import type * as ReactTable from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import {
  Button,
  CheckboxField,
  ColumnsIcon,
  List,
  Popover,
} from '@jane/shared/reefer';

interface Props {
  flightsTable: ReactTable.Table<ApiFlightSummary>;
}

const toggleColumnVisibilityOptions = [
  'states',
  'stores',
  'dateRange',
  'model',
  'products',
  'placement',
  'dailyBudget',
  'spend',
  'bid',
  'frequencyCap',
  'impressions',
  'displaysOn',
];

export const ColumnVisibilityPopover = ({ flightsTable }: Props) => {
  return (
    <Popover
      label="column-selector-popover"
      noMinWidth={true}
      target={
        <Button.Icon
          label="select visible columns"
          variant="secondary"
          icon={<ColumnsIcon color="primary" />}
        />
      }
    >
      <Popover.Content label="column-selector-popover-content">
        <List label="column-selector-list">
          {toggleColumnVisibilityOptions.map((col) => {
            if (col === 'dateRange') {
              const startDateCol = flightsTable.getColumn('startDate');
              const endDateCol = flightsTable.getColumn('endDate');
              if (!startDateCol || !endDateCol) return null;
              return (
                <List.Item key={'dateRange'}>
                  <CheckboxField
                    label={'Date range'}
                    name={'dateRange'}
                    checked={
                      startDateCol.getIsVisible() && endDateCol.getIsVisible()
                    }
                    onChange={(value) => {
                      startDateCol.toggleVisibility(value);
                      endDateCol.toggleVisibility(value);
                    }}
                  />
                </List.Item>
              );
            } else {
              const currentCol = flightsTable.getColumn(col);
              if (!currentCol) return null;
              return (
                <List.Item key={currentCol.id}>
                  <CheckboxField
                    label={capitalize(currentCol.columnDef.header as string)}
                    name={currentCol.id}
                    checked={currentCol.getIsVisible()}
                    onChange={currentCol.toggleVisibility}
                  />
                </List.Item>
              );
            }
          })}
        </List>
      </Popover.Content>
    </Popover>
  );
};
