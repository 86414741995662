import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { ReadMore as ReadMoreV1 } from '@jane/shared/reefer-v1';
import { ReadMore as ReadMoreV2 } from '@jane/shared/reefer-v2';
import type { ReadMoreProps } from '@jane/shared/reefer-v2';

export const ReadMore = (props: ReadMoreProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ReadMoreV2 {...props} />;
  }
  return <ReadMoreV1 {...props} />;
};
