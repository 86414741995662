import styled from '@emotion/styled';

import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { Flex, Form, Modal } from '@jane/shared/reefer';

import { AdDetailsSection } from './details/adDetailsSection';
import { AdPricingSection } from './pricing/adPricingSection';
import { AdProductsSection } from './products/adProductsSection';
import { AdSchedulingSection } from './scheduling/adSchedulingSection';
import { AdTargetingSection } from './targeting/adTargetingSection';
import type { UseAdBuilderFormReturn } from './useAdBuilderForm';

interface AdBuilderModalProps {
  adBuilderForm: UseAdBuilderFormReturn;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const AdBuilderFormWrapper = styled.div(() => ({
  maxWidth: 944,
  minWidth: 550,
  margin: '0 auto',
}));

export const AdBuilderModal = ({
  isOpen,
  setIsOpen,
  adBuilderForm,
}: AdBuilderModalProps) => {
  const { formMethods, onSubmit, resetWithDefaults } = adBuilderForm;
  const {
    formState: { isDirty, isSubmitting },
  } = formMethods;

  return (
    <ConfirmDiscardWrapper
      appId="root"
      open={isOpen}
      setOpen={(val) => {
        setIsOpen(val);
        if (!val) resetWithDefaults();
      }}
      hasChanges={isDirty}
      variant="full-screen"
      contentLabel="create ad form"
    >
      <Form.BaseForm
        formMethods={formMethods}
        name="ad-builder"
        onSubmit={() => onSubmit()}
        data-testid="ad-builder-form"
      >
        <Modal.Header
          title="Create ad"
          actions={
            <Flex gap={12}>
              <Form.SubmitButton
                data-testid="submit-ad"
                variant="primary"
                label="Create"
                loading={isSubmitting}
              />
            </Flex>
          }
        />
        <Modal.Content>
          <AdBuilderFormWrapper>
            <Flex gap={24} flexDirection="column">
              <AdDetailsSection />
              <AdProductsSection />
              <AdTargetingSection />
              <AdPricingSection />
              <AdSchedulingSection />
            </Flex>
          </AdBuilderFormWrapper>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmDiscardWrapper>
  );
};
