import { useLocation } from 'react-router-dom';

import { useAuth, useUser } from '@jane/brands/hooks';
import { brandsPaths } from '@jane/brands/util';
import { config } from '@jane/shared/config';
import {
  Button,
  Flex,
  HeartFilledIcon,
  Link,
  List,
  Popover,
  ProfileIcon,
  Typography,
} from '@jane/shared/reefer';

import { RenderIfAuthorized } from '../auth/RenderIfAuthorized';

/**
 * Shared navigation bar for the brand dashboard
 */
export const BrandPortalNavBar = () => {
  const { logout } = useAuth();
  const { email } = useUser();

  return (
    <Flex
      background="grays-ultralight"
      justifyContent="space-between"
      py={16}
      px={24}
    >
      <nav aria-label="dashboard-menu">
        <Flex as="ul">
          <NavLink path={brandsPaths.products()} label="Products" />

          <RenderIfAuthorized requiredPermission="accessBrandPages">
            <NavLink path={brandsPaths.brandThemes()} label="Brand pages" />
          </RenderIfAuthorized>

          <RenderIfAuthorized requiredPermission="accessAnalytics">
            <NavLink path={brandsPaths.analytics()} label="Analytics" />
          </RenderIfAuthorized>

          <RenderIfAuthorized requiredPermission="accessDigitalMerchandising">
            <NavLink
              path={brandsPaths.digitalMerchandising()}
              label="Digital merchandising"
            />
          </RenderIfAuthorized>
        </Flex>
      </nav>

      <Flex gap={16}>
        <Popover
          label="navbar-account-dropdown"
          target={<Button.Icon icon={<ProfileIcon />} />}
          alignment={{ horizontal: 'right' }}
          data-testid="navbar-account-dropdown-button"
        >
          <Popover.Content label="test">
            <List label="navbar-account-links">
              {email && (
                <List.Item>
                  <Typography variant="body-bold">{email}</Typography>
                </List.Item>
              )}
              <RenderIfAuthorized requiredPermission="manageUsers">
                <List.Item>
                  <Link
                    variant="minimal"
                    href={`${config.brandServerUrl}/admin`}
                    target="_self"
                  >
                    Brand Admin
                  </Link>
                </List.Item>
              </RenderIfAuthorized>

              <List.Item>
                <Link variant="minimal" onClick={logout} color="error">
                  Logout
                </Link>
              </List.Item>
            </List>
          </Popover.Content>
        </Popover>

        <HeartFilledIcon m={12} />
      </Flex>
    </Flex>
  );
};

type Props = {
  label: string;
  path: string;
};

const NavLink = ({ path, label }: Props) => {
  const { pathname } = useLocation();
  const isActive = pathname.match(new RegExp(path));

  return (
    <List.Item px={0} py={0}>
      <Button
        to={path}
        label={label}
        variant={isActive ? 'tertiary-selected' : 'tertiary'}
        aria-current={isActive && 'page'}
        target="_self"
      />
    </List.Item>
  );
};
