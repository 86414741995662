/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Columns24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 22H21C21.5523 22 22 21.5523 22 21V3C22 2.44772 21.5523 2 21 2H3C2.44772 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22ZM4 4V20H8V4H4ZM16 4V20H20V4H16ZM14 4H10V20H14V4Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Columns24,
  sm: Columns24,
  lg: Columns24,
  xl: Columns24,
  xxl: Columns24,
  xxxl: Columns24,
};

export const ColumnsIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'columns-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
