/* eslint-disable react/jsx-no-useless-fragment */
// disabling jsx-no-useless-fragments to allow returning just children
import type { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export type RedirectIfProps = {
  children: ReactNode;
  condition: boolean;
  to?: string;
};

/**
 * This component can be used to conditionally gate routes. Originally designed
 * for use with feature flags, to disable routes based on passing in a feature
 * flag.
 * @example
 * <Route path="/some-gated-path" element={
 *   <RedirectIf condition={flagState} to="/" >
 *     <SomeGatedComponent />
 *   </RedirectIf>
 * } />
 */
export const RedirectIf = ({ children, condition, to }: RedirectIfProps) => {
  if (condition) return <Navigate to={to || '/'} />;

  return <>{children}</>;
};
