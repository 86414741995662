import { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';

import type { FetchStoresParams, JamStore } from '@jane/ad-manager/data-access';
import { useInfiniteStores } from '@jane/ad-manager/data-access';
import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import {
  Box,
  Button,
  DismissIcon,
  Flex,
  Grid,
  Typography,
} from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../../useAdBuilderForm';

interface StoreCapsuleButtonProps {
  label: string;
  onClick: () => unknown;
}

const StoreCapsuleButton = ({ onClick, label }: StoreCapsuleButtonProps) => (
  <Button
    endIcon={<DismissIcon color="inherit" />}
    label={label}
    key={label}
    onClick={onClick}
    data-testid={`remove-store-button-${label}`}
    type="button"
    variant="secondary"
  />
);

enum Placement {
  CartToppers = 'cart_toppers',
  InlineProduct = 'menu_inline',
  MagicRow = 'magic_row',
  MenuRow = 'menu_top_row',
  ProductPage = 'pdp_row',
}

const PLACEMENTS_MAP: Record<string, Placement> = {
  'Menu row': Placement.MenuRow,
  'Product page': Placement.ProductPage,
  'Cart toppers': Placement.CartToppers,
  'Inline product': Placement.InlineProduct,
  'Recommended row': Placement.MagicRow,
};

interface StoreTargetsFormProps {
  isExcludedStores: boolean;
  onStoreIdsChange: (...event: any[]) => void;
  storeIds:
    | FlatAdSubmissionForm['storeIds']
    | FlatAdSubmissionForm['excludedStoreIds'];
}

export const StoreTargetsForm = ({
  isExcludedStores,
  onStoreIdsChange,
  storeIds,
}: StoreTargetsFormProps) => {
  const [openStoreSelectorModal, setOpenStoreSelectorModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const storeCacheRef = useRef<{ [key: string]: JamStore }>({});
  const storeCache = storeCacheRef.current;

  const sectionTitle = isExcludedStores
    ? 'Stores to exclude'
    : 'Stores to target';
  const [adPlacement, isDuplicate] = useWatch<
    FlatAdSubmissionForm,
    ['adPlacement', '_isDuplicate']
  >({ name: ['adPlacement', '_isDuplicate'] });
  const enabledPlacementParams = adPlacement.map(
    (placement: string) => PLACEMENTS_MAP[placement]
  );
  const shouldFetchByStoreIds = () =>
    isDuplicate && storeIds.length > 0 && !searchFilter;

  // fetch for store modal
  const fetchForStoreModalParams: FetchStoresParams = shouldFetchByStoreIds()
    ? {
        page: 1,
        ids: storeIds,
        perPage: 500,
      }
    : {
        page: 1,
        name: searchFilter,
        perPage: 50,
        enabledPlacements: enabledPlacementParams,
      };
  const {
    data: storeModalData,
    isFetching: isFetchingStoreModal,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteStores(fetchForStoreModalParams);
  const storeModalStores =
    storeModalData?.pages?.reduce<JamStore[]>(
      (accumulator, page) => accumulator.concat(page.stores),
      []
    ) || [];

  // update store cache
  storeModalStores.forEach((store) => {
    if (storeCache[store.id.toString()]) return;
    storeCache[store.id.toString()] = store;
  });

  // filter out invalid initial store ids on duplication mode
  useEffect(() => {
    if (!isDuplicate || isFetchingStoreModal) return;

    const validStoreIds = Object.keys(storeCache).map(String);
    const filteredStoreIDs = storeIds.filter((sid) =>
      validStoreIds.includes(sid)
    );
    if (filteredStoreIDs.length !== storeIds.length) {
      onStoreIdsChange(filteredStoreIDs);
    }
  }, [isDuplicate, isFetchingStoreModal]);

  // sort currentStores by store name
  if (!isFetchingStoreModal) {
    storeIds.sort((a, b) =>
      storeCache[a]?.name.localeCompare(storeCache[b]?.name)
    );
  }

  const onClickRemoveStore = (storeIdToRemove: string) => {
    const updatedStoreIds = storeIds.filter((sid) => sid !== storeIdToRemove);
    onStoreIdsChange(updatedStoreIds);
  };

  return (
    <Grid.Container
      direction="row"
      spacing={16}
      data-testid={`store-targets-${isExcludedStores ? 'exclude' : 'include'}`}
    >
      <Grid.Item xs={12}>
        <Typography variant="body-bold">{sectionTitle}</Typography>
      </Grid.Item>

      <Grid.Item xs={12}>
        <Flex
          inline={true}
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Button
            size="default"
            variant="tertiary"
            label="Select stores"
            onClick={() => setOpenStoreSelectorModal(true)}
          />
        </Flex>
      </Grid.Item>

      <Grid.Item xs={12}>
        <Flex flexWrap="wrap">
          {storeIds.map(
            (storeId) =>
              storeCache[storeId] && (
                <Box mr={24} mt={8} mb={16} key={storeCache[storeId].id}>
                  <StoreCapsuleButton
                    label={storeCache[storeId].name}
                    onClick={() => onClickRemoveStore(storeId)}
                  ></StoreCapsuleButton>
                </Box>
              )
          )}
        </Flex>
      </Grid.Item>

      <>
        {openStoreSelectorModal && (
          <StoreSelectModal
            storesData={storeModalStores}
            isFetchingStores={isFetchingStoreModal}
            searchFilterPlaceholder="Search by name"
            selectedStoreIds={storeIds}
            onSubmit={(storeIds) => {
              onStoreIdsChange(storeIds.map(String));
              setOpenStoreSelectorModal(false);
            }}
            submitButtonType={SUBMIT_BUTTON_VARIANTS.save}
            closeModal={() => setOpenStoreSelectorModal(false)}
            onFilterChange={() => undefined}
            onToggleAll={() => undefined}
            onToggleStore={() => undefined}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            onSearchCallback={(s) => setSearchFilter(s)}
            initialSearchFilter={searchFilter}
          />
        )}
      </>
    </Grid.Container>
  );
};
