import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Form, Typography } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';

export const MenuRowTitle = () => {
  const { selectedBrand } = useSelectedBrandContext();
  const { resetField, getValues, getFieldState } =
    useFormContext<FlatAdSubmissionForm>();

  // if there is a selected brand and menu row title has not been set (or touched), default it to brand name
  useEffect(() => {
    const brandName = selectedBrand?.name;
    const menuRowTitle = getValues('menuRowTitle');
    const menuRowTitleTouched = getFieldState('menuRowTitle').isTouched;

    if (brandName && !menuRowTitle && !menuRowTitleTouched) {
      resetField('menuRowTitle', { defaultValue: selectedBrand?.name });
    }
  }, [getFieldState, getValues, resetField, selectedBrand?.name]);

  return (
    <>
      <Typography variant="body-bold" mb={12}>
        Menu row title
      </Typography>
      <Form.TextField
        data-testid="menu-row-title-input"
        type="text"
        label="Menu row title"
        name="menuRowTitle"
        required={true}
        shouldUnregister={false}
        labelHidden
      />
    </>
  );
};
