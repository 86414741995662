import styled from '@emotion/styled';

import { Typography } from '@jane/shared/reefer';

import type { AdType } from '../useAdBuilderForm';

interface Props {
  adType: AdType;
  isSelected: boolean;
  onClick: () => void;
}

const ProductGroupOptionsWrapper = styled.div<{
  isSelected: boolean;
}>(({ theme, isSelected }) => ({
  borderRadius: theme.borderRadius.xl,
  border: `2px solid ${theme.colors.grays.light}`,
  ...(isSelected && {
    border: `2px solid ${theme.colors.brand.purple.main}`,
  }),
  padding: 24,
  display: 'grid',
  alignItems: 'center',
  cursor: 'pointer',
}));

const ProductGroupsOptions = styled.div(({ theme }) => ({
  marginBottom: 16,
  background: theme.colors.grays.ultralight,
  borderRadius: theme.borderRadius.xs,
  padding: 16,
  display: 'grid',
  gridColumnGap: 8,
  gridTemplateColumns: `repeat(5, 1fr)`,
}));

const OptionDescriptionWrapper = styled.div(() => ({
  maxWidth: '75%',
}));

const ProductGroupSymbol = styled.div<{
  isCentre?: boolean;
  isSelected?: boolean;
  topColor: string;
}>(({ theme, topColor, isCentre, isSelected }) => {
  const topColorMap: Record<string, string> = {
    'purple-main': theme.colors.brand.purple.main,
    'grays-mid': theme.colors.grays.mid,
    'grays-light': theme.colors.grays.light,
  };
  if (topColor === 'grays-light' && isCentre) {
    topColor = isSelected ? 'purple-main' : 'grays-mid';
  }

  return {
    borderRadius: theme.borderRadius.xs,
    height: 88,
    background: `linear-gradient(to bottom, ${topColorMap[topColor]} 50%, ${theme.colors.grays.white} 50%)`,
  };
});

export const ProductGroupOption = ({ adType, isSelected, onClick }: Props) => {
  const isCPC = adType === 'cpc';
  const optionTitle = isCPC
    ? 'Individual products (CPC)'
    : 'Product group (CPM)';
  const optionDescription = isCPC
    ? 'Promote products inline within targeted categories and collections.'
    : 'Showcase many of your products together in a consolidated row.';
  const topColor = isCPC
    ? 'grays-light'
    : isSelected
    ? 'purple-main'
    : 'grays-mid';

  return (
    <ProductGroupOptionsWrapper
      data-testid={`${adType}-option`}
      isSelected={isSelected}
      onClick={onClick}
    >
      <ProductGroupsOptions>
        <ProductGroupSymbol topColor={topColor} />
        <ProductGroupSymbol topColor={topColor} />
        <ProductGroupSymbol
          topColor={topColor}
          isCentre
          isSelected={isSelected}
        />
        <ProductGroupSymbol topColor={topColor} />
        <ProductGroupSymbol topColor={topColor} />
      </ProductGroupsOptions>
      <Typography variant="body-bold">{optionTitle}</Typography>
      <OptionDescriptionWrapper>
        <Typography variant="body" color="grays-mid">
          {optionDescription}
        </Typography>
      </OptionDescriptionWrapper>
    </ProductGroupOptionsWrapper>
  );
};
