import { flexRender } from '@tanstack/react-table';
import type * as ReactTable from '@tanstack/react-table';
import React from 'react';
import type { InViewHookResponse } from 'react-intersection-observer';

import type { Product } from '@jane/ad-manager/data-access';
import { Box, Flex, Link } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { ProductTableColumnFilter } from './productTableColumnFilter';
import { ProductTableSearch } from './productTableSearch';

export type ProductTableWithMeta = {
  options?: {
    meta?: {
      fetchMoreRef?: InViewHookResponse['ref'];
      total?: number;
    };
  };
} & ReactTable.Table<Product>;

export type ProductColumnWithMeta = {
  meta?: {
    filterLabel?: string;
    filterValues?: string[];
  };
} & ReactTable.ColumnDef<Product>;

export const ProductTable = ({ table }: { table: ProductTableWithMeta }) => {
  return (
    <Box px={40} data-testid="product-table">
      <Flex mt={40} mb={24} gap={16} alignItems="center">
        <ProductTableSearch column={table.getColumn('name')} />
        {table
          .getAllColumns()
          .filter((col) => col.getCanFilter())
          .map((col) => (
            <ProductTableColumnFilter
              key={col.id}
              column={col}
              filterOptions={
                (col.columnDef as ProductColumnWithMeta)?.meta?.filterValues ??
                []
              }
            />
          ))}
        <Link
          onClick={() => {
            table.resetColumnFilters();
          }}
        >
          Clear filters
        </Link>
      </Flex>
      <Table scrollable>
        <Table.Head fixed>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeaderCell
                  key={header.id}
                  width={`${header.getSize()}px`}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              data-testid={`select-products-table-row-${row.id}`}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div ref={table.options.meta?.fetchMoreRef}></div>
    </Box>
  );
};
