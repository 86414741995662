import type * as ReactTable from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';

import type { Product } from '@jane/ad-manager/data-access';
import {
  Button,
  CheckboxField,
  ChevronDownIcon,
  Flex,
  List,
  Popover,
} from '@jane/shared/reefer';

import type { ProductColumnWithMeta } from './productTable';

export const ProductTableColumnFilter = ({
  column,
  filterOptions,
}: {
  column: ReactTable.Column<Product>;
  filterOptions: string[];
}) => {
  const [checkedOptions, setCheckedOptions] = useState<string[]>(
    (column.getFilterValue() ?? []) as string[]
  );

  const onClickCheckBox = (option: string) => {
    let newFilterValues: string[];
    const oldFilterValues = (column.getFilterValue() ?? []) as string[];

    if (oldFilterValues.includes(option)) {
      newFilterValues = oldFilterValues.filter((o) => o !== option);
    } else {
      newFilterValues = [...oldFilterValues, option];
    }
    column.setFilterValue(newFilterValues);
    setCheckedOptions(newFilterValues);
  };

  const filterLabel =
    (column.columnDef as ProductColumnWithMeta).meta?.filterLabel ||
    capitalize(column.id);

  return (
    <Popover
      label={filterLabel}
      target={
        <Button
          label={filterLabel}
          variant="tertiary"
          endIcon={<ChevronDownIcon size="sm" />}
        />
      }
    >
      {() => (
        <Popover.Content label="test">
          <List label="test-list">
            {filterOptions.map((option, index) => (
              <List.Item key={index}>
                <Flex>
                  <CheckboxField
                    label={capitalize(option)}
                    name={`${option}-checkbox`}
                    onClick={() => onClickCheckBox(option)}
                    checked={checkedOptions.includes(option)}
                  />
                </Flex>
              </List.Item>
            ))}
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};
