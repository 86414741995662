import styled from '@emotion/styled';
import { useWatch } from 'react-hook-form';

import { Banner, Flex, Form, InfoIcon, Typography } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';

const FrequencyCappingInputs = styled.div(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
  gap: 24,
}));

const TIME_UNIT_OPTIONS = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
];

export const FrequencyCapping = () => {
  const [impressions, isPriority, timePeriod, timeUnit] = useWatch<
    FlatAdSubmissionForm,
    ['impressions', 'isPriority', 'timePeriod', 'timeUnit']
  >({
    name: ['impressions', 'isPriority', 'timePeriod', 'timeUnit'],
  });

  return (
    <Flex gap={24} flexDirection="column">
      <Flex flexDirection="column">
        <Typography variant="body-bold">Frequency capping</Typography>
        <Typography variant="body">
          Cap the number of times the flight can be displayed to any particular
          person in a particular time period.
        </Typography>
      </Flex>
      <FrequencyCappingInputs>
        <Form.NumberField
          label="Impressions"
          name="impressions"
          disabled={!!isPriority}
          shouldUnregister={false}
          required
        />
        <Form.NumberField
          label="Time period"
          name="timePeriod"
          disabled={!!isPriority}
          shouldUnregister={false}
          required
        />
        <Form.SelectField
          name="timeUnit"
          label="Time unit"
          labelHidden
          options={TIME_UNIT_OPTIONS}
          mt={32}
          disabled={!!isPriority}
          shouldUnregister={false}
          required
        />
      </FrequencyCappingInputs>
      {!!impressions && !!timePeriod && !!timeUnit && (
        <Banner
          icon={<InfoIcon />}
          typography="body"
          label={`For each user, display this flight up to ${impressions} times every ${timePeriod} ${timeUnit}.`}
          variant="info"
        />
      )}
    </Flex>
  );
};
