import { useWatch } from 'react-hook-form';

import {
  Banner,
  Box,
  Flex,
  Form,
  Grid,
  InfoIcon,
  Typography,
} from '@jane/shared/reefer';

import { AdBuilderSectionContainer } from '../adBuilderSectionContainer';
import type { AdType, FlatAdSubmissionForm } from '../useAdBuilderForm';
import { IsPriority } from './isPriority';

const DailyBudget = () => (
  <>
    <Typography variant="body-bold" mb={12}>
      Daily budget
    </Typography>
    <Form.NumberField
      allowedDecimalPlaces={2}
      data-testid="daily-budget"
      label="Daily budget"
      labelHidden
      mb={12}
      min={1}
      name="budget"
      required
      startUnit="$"
      step={1}
      shouldUnregister={false}
    />
    <Typography color="grays-mid">
      Cap the daily amount you want to spend on this ad.
    </Typography>
  </>
);

const Bid = ({ adType }: { adType: AdType }) => {
  const headerText =
    adType === 'cpm'
      ? 'Cost per 1,000 impressions bid (CPM)'
      : 'Cost per click bid (CPC)';
  const footerText =
    adType === 'cpm'
      ? 'The price you pay for every 1,000 times a customer sees your ad. Minimum of $25.00 required.'
      : 'The price you pay for every time a customer clicks your ad. Minimum of $1.00 required.';
  return (
    <>
      <Typography variant="body-bold" mb={12}>
        {headerText}
      </Typography>
      <Form.NumberField
        allowedDecimalPlaces={2}
        data-testid="bid"
        label={`${adType.toUpperCase()} bid`}
        labelHidden
        mb={12}
        min={adType === 'cpm' ? 25 : 1}
        name="bid"
        required
        startUnit="$"
        step={0.01}
        shouldUnregister={false}
      />
      <Typography color="grays-mid">{footerText}</Typography>
    </>
  );
};

const PriorityCpmBudget = () => (
  <Box>
    <Typography variant="body-bold" mb={12}>
      Budget
    </Typography>
    <Form.NumberField
      allowedDecimalPlaces={2}
      data-testid="budget-cpm"
      label="Cpm budget"
      labelHidden
      mb={12}
      min={5000}
      name="budget"
      required
      startUnit="$"
      step={1}
      shouldUnregister={false}
    />
    <Typography color="grays-mid">
      Define a total budget for prioritized placement. Minimum $5,000.00
      required.
    </Typography>
  </Box>
);

const PricingInfoBanner = () => (
  <Banner
    icon={<InfoIcon />}
    typography="body"
    variant="info"
    label="Based on your current ad configuration, we’ve recommended a daily budget and cost per impression bid for you. Feel free to make adjustments and see how performance and returns are impacted."
    full
  />
);

export const AdPricingSection = () => {
  const [adType, isPriority] = useWatch<
    FlatAdSubmissionForm,
    ['adType', 'isPriority']
  >({
    name: ['adType', 'isPriority'],
  });

  const isCpm = adType === 'cpm';
  const isCpmOrCpc = adType === 'cpm' || adType === 'cpc';

  return (
    <AdBuilderSectionContainer>
      <Flex flexDirection="column">
        <Typography variant="header-bold" branded mb={24}>
          Pricing
        </Typography>
        <Flex flexDirection="column" gap={24} data-testid="pricing-section">
          {isCpm && <IsPriority />}
          {!isPriority && isCpmOrCpc && (
            <Grid.Container>
              <Grid.Item xs={6}>
                <DailyBudget />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Bid adType={adType} />
              </Grid.Item>
            </Grid.Container>
          )}
          {isPriority && isCpm && <PriorityCpmBudget />}
          {!isPriority && <PricingInfoBanner />}
        </Flex>
      </Flex>
    </AdBuilderSectionContainer>
  );
};
