import { uploadFileToS3 } from '@iheartjane/react-image-list';
import type { FileWithPath } from 'react-dropzone';

import { config } from '@jane/shared/config';

import { validators } from './validators';
import type { ImageValidationParams } from './validators';

const VALID_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const MAX_IMAGE_SIZE = 4_000_000;
const MIN_IMAGE_WIDTH = 1920;
const MIN_IMAGE_HEIGHT = 1280;

const s3BucketUrl = () => `${config.brandServerUrl}/jam/file-upload`;

const validateImageFileType = (file: FileWithPath) =>
  VALID_IMAGE_TYPES.includes(file.type);

const validateImageFileSize = (file: FileWithPath) =>
  file.size <= MAX_IMAGE_SIZE;

const validateImageDimensions = (imgHeight: number, imgWidth: number) =>
  imgHeight >= MIN_IMAGE_HEIGHT && imgWidth >= MIN_IMAGE_WIDTH;
const imageFileTypeError = 'Please upload a jpg, jpeg or png file';
const imageFileSizeError = 'Your upload exceeds maximum file size of 4MB';
const imageDimensionsError =
  'Your upload does not meet image dimension requirements';

export const uploadBrandImage = async (file: File): Promise<string> => {
  const imageParams: ImageValidationParams = {
    file,
    fileSizeErrorMessage: imageFileSizeError,
    fileSizeValidator: validateImageFileSize,
    fileTypeErrorMessage: imageFileTypeError,
    fileTypeValidator: validateImageFileType,
    imageDimensionsErrorMessage: imageDimensionsError,
    imageDimensionsValidator: validateImageDimensions,
  };
  const errorMessage = await validators.isValidImage(imageParams);
  if (errorMessage) {
    throw errorMessage;
  }
  return await uploadFileToS3(s3BucketUrl(), file, {
    credentials: 'include',
  });
};
