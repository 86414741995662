import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { searchAlgoliaBrands } from '@jane/search/data-access';
import type {
  SearchAlgoliaBrandsParams,
  SearchAlgoliaLocalBrandsParams,
  SearchResponse,
} from '@jane/search/types';
import { buildGoldUserSegmentsFilter } from '@jane/search/util';
import {
  useGetJaneDeviceId,
  useUserSegmentIds,
} from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { trackError } from '@jane/shared/util';

import { useProducts } from '../products';
import { useUserPreferredStoreIds } from '../stores';
import { brandOrder } from './brands.util';

const METERS_PER_MILE = 1609.34;

type ProductsFacets = SearchResponse['facets'];

const getBrands = async (params: SearchAlgoliaBrandsParams) => {
  const brands = await searchAlgoliaBrands({
    ...params,
  });
  return brands;
};

export const useBrands = (params: SearchAlgoliaBrandsParams) => {
  return useQuery({
    queryFn: async () => await getBrands(params),
    queryKey: ['brands', params],
  });
};

const getLocalBrands = async ({
  hasBrandDiscount,
  localProductsFacets = {},
  localProductsWithBrandDiscountFacets = {},
  ...params
}: SearchAlgoliaLocalBrandsParams & {
  hasBrandDiscount?: boolean;
  localProductsFacets?: ProductsFacets;
  localProductsWithBrandDiscountFacets?: ProductsFacets;
}) => {
  let results = null;
  try {
    const brandIdFacets = localProductsFacets['product_brand_id'] || {};
    const brandIdWithDiscountFacets =
      localProductsWithBrandDiscountFacets['product_brand_id'] || {};

    // NOTE: If we call this fn w/ hasBrandDiscount, then we only want brands w/ local products w/ discounts.
    const facets = hasBrandDiscount ? brandIdWithDiscountFacets : brandIdFacets;
    const brandIds = brandIdFacets ? Object.keys(facets) : [];

    if (!brandIds.length && params.userLocation.coordinates) {
      return {
        applicableBrandSpecialIds: [],
        hits: [],
        page: 0,
        nbHits: 0,
        nbPages: 0,
      };
    }

    const brands = await searchAlgoliaBrands({
      ids: brandIds,
      ...params,
    });

    const applicableBrandSpecialIds = Object.keys(
      localProductsWithBrandDiscountFacets['applicable_brand_special_ids'] || {}
    );

    const hits = brands.hits.map((hit) => ({
      ...hit,
      // NOTE: Overwrites has_active_brand_special only when brand has local products that have a brand discount.
      has_active_brand_special:
        hit.has_active_brand_special &&
        (brandIdWithDiscountFacets[hit.id] || 0) > 0,
      productCount: brandIdFacets[hit.id],
    }));

    // NOTE(elliot): Only sort if we have local product counts.
    if (!params.currentSort && params.userLocation.coordinates) {
      hits.sort(brandOrder);
    }

    results = {
      ...brands,
      hits,
      applicableBrandSpecialIds,
    };
  } catch (error) {
    trackError('Error fetching home page brands', error);
  }
  return results;
};

const useLocalProducts = ({
  // NOTE: pass list of brand ids to check if brand has local products matching passed in filters.
  brandIds,
  storeSpecificProduct,
  enabled,
  userLocation,
  userPreferences,
}: Pick<
  SearchAlgoliaLocalBrandsParams,
  'storeSpecificProduct' | 'userLocation' | 'userPreferences'
> & {
  brandIds?: number[];
  enabled?: boolean;
}) => {
  const searchRadius = userPreferences?.storeSearchRadius || 35;
  const storeType = userPreferences?.storeType;

  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const { data: jdid } = useGetJaneDeviceId();
  const { data: userSegments } = useUserSegmentIds(jdid);
  const { data: userPreferredStoreIds } = useUserPreferredStoreIds({
    coordinates: userLocation?.coordinates,
    zipcode: userLocation?.zipcode,
    userPreferences,
  });

  const enabledForGold = janeGoldSegmentation ? Boolean(userSegments) : true;

  const productParams = {
    enabled:
      !!userPreferredStoreIds &&
      Boolean(userLocation?.coordinates) &&
      enabledForGold &&
      enabled,
    options: {
      aroundLatLng: userLocation?.coordinates
        ? `${userLocation?.coordinates.lat}, ${userLocation?.coordinates.long}`
        : undefined,
      aroundRadius: Math.ceil(searchRadius * METERS_PER_MILE),
      // NOTE: We only need the facets to get brand ids and product counts for the brand ids.
      attributesToRetrieve: ['product_brand_id'],
      facets: ['product_brand_id', 'applicable_brand_special_ids'],
      facetFilters: [
        'at_visible_store:true',
        ...(brandIds
          ? brandIds.map((brandId) => `product_brand_id:${brandId}`)
          : []),
      ],
    },
    storeIds: userPreferredStoreIds,
    storeSpecificProduct,
    staticFilters:
      storeType && storeType !== 'all'
        ? `store_types:"${storeType}"`
        : undefined,
  };

  const { data: localProducts } = useProducts(productParams);

  const { data: localProductsWithBrandDiscount } = useProducts({
    ...productParams,
    hasBrandDiscount: true,
    staticFilters: janeGoldSegmentation
      ? buildGoldUserSegmentsFilter(userSegments)
      : undefined,
  });

  return {
    localProducts,
    localProductsWithBrandDiscount,
  };
};

export const useLocalBrands = ({
  brandIds,
  enabled = true,
  storeSpecificProduct,
  ...params
}: SearchAlgoliaLocalBrandsParams & { enabled?: boolean }) => {
  const { userLocation, userPreferences } = params;

  const { localProducts, localProductsWithBrandDiscount } = useLocalProducts({
    brandIds,
    storeSpecificProduct,
    enabled,
    userLocation,
    userPreferences,
  });

  const searchParams = {
    localProductsFacets: localProducts?.facets,
    localProductsWithBrandDiscountFacets:
      localProductsWithBrandDiscount?.facets,
    ...params,
  };

  return useQuery({
    // NOTE(elliot): Defer fetching brands until we get user preferred stores.
    enabled:
      Boolean(localProducts && localProductsWithBrandDiscount && enabled) ||
      Boolean(!userLocation.coordinates && enabled),
    queryFn: async () => await getLocalBrands(searchParams),
    queryKey: ['localBrands', searchParams],
  });
};

export const useInfiniteLocalBrands = (
  params: SearchAlgoliaLocalBrandsParams
) => {
  const { localProducts, localProductsWithBrandDiscount } =
    useLocalProducts(params);

  const searchParams = {
    localProductsFacets: localProducts?.facets,
    localProductsWithBrandDiscountFacets:
      localProductsWithBrandDiscount?.facets,
    ...params,
  };

  return useInfiniteQuery({
    // NOTE(elliot): If no location is set we don't need local product facets.
    enabled: Boolean(
      (localProducts?.facets && localProductsWithBrandDiscount?.facets) ||
        !params.userLocation.coordinates
    ),
    queryKey: ['infiniteLocalBrands', searchParams],
    getNextPageParam: (lastPage) => {
      if (
        typeof lastPage?.page === 'number' &&
        lastPage.page + 1 < lastPage.nbPages
      ) {
        return lastPage.page + 1;
      }
      return undefined;
    },
    queryFn: async ({ pageParam }: { pageParam?: number }) => {
      return await getLocalBrands({
        ...searchParams,
        page: pageParam,
      });
    },
  });
};
