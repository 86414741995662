import styled from '@emotion/styled';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { useMediaFormUtil } from '@jane/ad-manager/hooks';
import { uploadBrandImage } from '@jane/ad-manager/util';
import { ConfirmDeleteModal } from '@jane/shared/components';
import { Flex, Link, Loading, Typography } from '@jane/shared/reefer';

import { EditVideoDetails } from './editAssetForm';
import { MaybeHidden } from './maybeHidden';
import { PlaybackIcon } from './playbackIcon';

const LoadingArea = styled.div(({ theme }) => ({
  position: 'relative',
  height: 264,
  width: '100%',
  borderRadius: theme.borderRadius.sm,
}));

const MediaPreviewArea = styled.div<{
  imageUrl: string;
}>(({ theme, imageUrl }) => ({
  background: `url(${imageUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: 264,
  borderRadius: theme.borderRadius.sm,
}));

const IconWrapper = styled.div(() => ({
  position: 'relative',
  left: 'calc(50% - 48px/2)',
  top: 'calc(50% - 48px/2)',
  width: 48,
}));

interface Props {
  deleteMedia: (mediaKey: string) => void;
  isSaving: boolean;
  isSelected: boolean;
  isUploading: boolean;
  mediaKey: string;
  setError: (error: string) => void;
  setIsUploading: (isUploading: boolean) => void;
}
export const MediaPreview = ({
  isSaving,
  isSelected,
  isUploading,
  setIsUploading,
  mediaKey,
  deleteMedia,
  setError,
}: Props) => {
  const {
    getImageImageUrl,
    getMediaType,
    getVideoOverlayImageUrl,
    setImageImageUrl,
    setVideoOverlayImageUrl,
  } = useMediaFormUtil();
  const _mediaType = getMediaType(mediaKey);
  const isAVideo = _mediaType === 'video';
  const isAnImage = _mediaType === 'image';

  const isLoading = isSaving || isUploading;
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const replaceMedia = async (acceptedFiles: Array<File>) => {
    setError('');
    if (acceptedFiles.length === 0) return;
    setIsUploading(true);
    const file = acceptedFiles[0];
    try {
      const imageSrc = await uploadBrandImage(file);
      if (isAnImage) setImageImageUrl(mediaKey, imageSrc);
      if (isAVideo) setVideoOverlayImageUrl(mediaKey, imageSrc);
    } catch (errorMessage: unknown) {
      setError(errorMessage as string);
    }
    setIsUploading(false);
  };

  const { getInputProps, open } = useDropzone({ onDrop: replaceMedia });

  const overlayImageUrl = getVideoOverlayImageUrl(mediaKey);
  const imageUrl = getImageImageUrl(mediaKey);
  const imgSrc = isAVideo ? overlayImageUrl : imageUrl;

  const deleteImageText = 'Delete image';
  const replaceImageText = 'Replace image';

  return (
    <MaybeHidden isHidden={!isSelected}>
      <Flex justifyContent="space-between" alignItems="center" mb={16} mt={40}>
        <Flex flexDirection="column">
          <Typography>
            {isAnImage ? 'Preview' : 'Video cover preview'}
          </Typography>
        </Flex>
        <Flex gap={24}>
          {isSaving ? (
            <>
              <Typography>{deleteImageText}</Typography>
              <Typography>{replaceImageText}</Typography>
            </>
          ) : (
            <>
              <Link onClick={() => setConfirmDeleteModalOpen(true)}>
                {deleteImageText}
              </Link>
              <Link onClick={open}>
                {replaceImageText}
                <input
                  data-testid="media-replacement-upload"
                  {...getInputProps()}
                />
              </Link>
            </>
          )}
        </Flex>
      </Flex>
      {isUploading && (
        <LoadingArea>
          <Loading color="black" />
        </LoadingArea>
      )}
      {!isUploading && (
        <MediaPreviewArea imageUrl={imgSrc}>
          {isAVideo && (
            <IconWrapper>
              <PlaybackIcon />
            </IconWrapper>
          )}
        </MediaPreviewArea>
      )}
      {isAVideo && (
        <EditVideoDetails
          disabled={isLoading}
          isSelected={isSelected}
          mediaKey={mediaKey}
        />
      )}
      <ConfirmDeleteModal
        open={confirmDeleteModalOpen}
        setOpen={setConfirmDeleteModalOpen}
        confirmDelete={() => deleteMedia(mediaKey)}
        title="Delete image?"
        subtitle={
          isAVideo
            ? 'Are you sure you want to delete this cover image?'
            : 'Are you sure you want to delete this image?'
        }
      />
    </MaybeHidden>
  );
};
