import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  formatDatepickerString,
  nowInPacificTime,
  validationMessages,
  validators,
} from '@jane/ad-manager/util';
import { Flex, Form } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';

export const FlightDateRange = () => {
  const { getValues, trigger, watch, getFieldState } =
    useFormContext<FlatAdSubmissionForm>();

  const [endDate, startDate] = watch(['endDate', 'startDate']);
  const today = formatDatepickerString(nowInPacificTime());

  const validateStartDate = (startDate: string) => {
    const endDate = getValues('endDate');
    return (
      validators.isDateRangeValid(startDate, endDate) ||
      validationMessages.invalidStartDateRage
    );
  };

  const validateEndDate = (endDate: string) => {
    const startDate = getValues('startDate');
    return (
      validators.isDateRangeValid(startDate, endDate) ||
      validationMessages.invalidEndDateRage
    );
  };

  useEffect(() => {
    const { isTouched: isStartDateTouched } = getFieldState('startDate');
    if (isStartDateTouched) trigger('startDate');

    const { isTouched: isEndDateTouched } = getFieldState('endDate');
    if (isEndDateTouched) trigger('endDate');
  }, [endDate, getFieldState, startDate, trigger]);

  return (
    <Flex gap={24} data-testid="date-range-section">
      <Form.DateTimeField
        required
        label="Start date"
        name="startDate"
        min={today}
        width="100%"
        validate={validateStartDate}
        shouldUnregister={false}
      />
      <Form.DateTimeField
        required
        label="End date"
        name="endDate"
        min={today}
        width="100%"
        validate={validateEndDate}
        shouldUnregister={false}
      />
    </Flex>
  );
};
