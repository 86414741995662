import styled from '@emotion/styled';
import { createColumnHelper } from '@tanstack/react-table';
import type { NavigateFunction } from 'react-router-dom';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import {
  formatDatepickerString,
  parseUTCDateTimeInPT,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Box,
  Button,
  Link,
  List,
  Loading,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import type { RowActions } from './useFlightsTable';

const columnHelper = createColumnHelper<ApiFlightSummary>();

const StyledPopover = styled(Popover)`
  & #popover-content {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

const cellContentFormatter = (
  contentNamePlural: string,
  foldingThreshold: number,
  content?: string
) => {
  if (!content) {
    return <Typography color="grays-mid">None</Typography>;
  }

  const contentArray = content.split(',');
  if (contentArray.length === 0) return null;
  if (contentArray.length < foldingThreshold) {
    return contentArray.join(', ');
  }

  return (
    <StyledPopover
      target={
        <div>
          {contentArray.length} {contentNamePlural}
        </div>
      }
      label="states"
      openOn="hover"
      variant="info"
      noMinWidth={true}
      closeOnTargetClick={false}
    >
      <Popover.Content>
        <Typography>{contentArray.join(', ')}</Typography>
      </Popover.Content>
    </StyledPopover>
  );
};

const dateStringInPTFromISODate = (isoDateString?: string | null) => {
  if (!isoDateString) return 'Auto';

  return formatDatepickerString(parseUTCDateTimeInPT(isoDateString));
};

export const getColumns = ({
  onDuplicateRow,
  onSetActive,
  loadingFlightIds,
  navigate,
}: RowActions & {
  loadingFlightIds: number[];
  navigate: NavigateFunction;
}) => [
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      const isLoading = loadingFlightIds.indexOf(row.original.id) !== -1;

      if (isLoading)
        return (
          <Box pl={24} pt={8}>
            <Loading style={{ position: 'static' }} size="xs"></Loading>
          </Box>
        );

      return (
        <StyledPopover
          label="row-actions"
          target={
            <Button.Icon label="row-actions-button" icon={<MoreIcon />} />
          }
        >
          <Popover.Content label="row-actions-content">
            <List label="row-actions-list">
              <List.Item>
                <Link
                  variant="minimal"
                  onClick={() =>
                    navigate(brandsPaths.flightDetails(row.original.id))
                  }
                >
                  View detail
                </Link>
              </List.Item>
              <List.Item>
                <Link
                  variant="minimal"
                  onClick={() => onDuplicateRow(row)}
                  ariaLabel="duplicate flight"
                >
                  Duplicate
                </Link>
              </List.Item>
              <List.Item>
                <Link
                  onClick={() =>
                    onSetActive(row.original.id, !row.original.active)
                  }
                  variant="minimal"
                  ariaLabel={row.original.active ? 'Deactivate' : 'Activate'}
                >
                  {row.original.active ? 'Deactivate' : 'Activate'}
                </Link>
              </List.Item>
            </List>
          </Popover.Content>
        </StyledPopover>
      );
    },
  }),

  // id
  columnHelper.accessor(({ id }) => id.toString(), {
    id: 'id',
    header: 'flight', //TODO: sortable
  }),

  // states
  columnHelper.accessor(({ states }) => states?.sort().toString(), {
    cell: ({ getValue }) => cellContentFormatter('states', 4, getValue()),
    id: 'states',
    header: 'states',
  }),

  // stores
  columnHelper.accessor(
    ({ stores = [] }) =>
      stores
        .map((s) => s.id)
        .sort((a, b) => a - b)
        .toString(),
    {
      cell: ({ getValue }) => cellContentFormatter('stores', 2, getValue()),
      id: 'stores',
      header: 'stores',
    }
  ),

  // starts
  columnHelper.accessor('startDate', {
    cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
    header: 'starts',
  }),

  // ends
  columnHelper.accessor('endDate', {
    cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
    header: 'ends',
  }),

  // type
  columnHelper.accessor('model', {
    cell: ({ getValue }) => getValue().toUpperCase(),
    header: 'type',
  }),

  // products
  columnHelper.accessor(
    ({ selectedProducts = [] }) =>
      selectedProducts
        .map((sp) => sp.id)
        .sort((a, b) => a - b)
        .toString(),
    {
      cell: ({ getValue }) => cellContentFormatter('products', 2, getValue()),
      id: 'products',
      header: 'products',
    }
  ),

  // placement
  columnHelper.accessor(({ zones }) => zones?.sort().toString(), {
    cell: ({ getValue }) => cellContentFormatter('placements', 2, getValue()),
    id: 'placement',
    header: 'placement',
  }),

  // daily budget
  columnHelper.accessor(({ dailyBudget }) => dailyBudget?.toString(), {
    header: 'daily budget',
    cell: ({ getValue }) =>
      getValue() ? (
        `${formatCurrency(getValue())}`
      ) : (
        <Typography color="grays-mid">Not set</Typography>
      ),
    id: 'dailyBudget',
  }),

  // spend
  columnHelper.accessor(({ spend }) => spend.toString(), {
    id: 'spend',
    header: 'spend',
    cell: ({ getValue }) =>
      getValue() ? (
        `${formatCurrency(getValue())}`
      ) : (
        <Typography color="grays-mid">Not set</Typography>
      ),
  }),

  // bid
  columnHelper.accessor(({ bid }) => bid?.toString(), {
    id: 'bid',
    header: 'bid',
    cell: ({ getValue }) =>
      getValue() ? (
        `${formatCurrency(getValue())}`
      ) : (
        <Typography color="grays-mid">Not set</Typography>
      ),
  }),

  // frequency cap
  columnHelper.accessor(
    ({ frequencyCapUnit, frequencyCapDenominator, frequencyCapNumerator }) =>
      frequencyCapUnit && frequencyCapUnit !== 'disabled'
        ? `${frequencyCapNumerator}/${frequencyCapDenominator} ${frequencyCapUnit}`
        : undefined,
    {
      id: 'frequencyCap',
      header: 'frequency cap',
      cell: ({ getValue }) =>
        getValue() ?? <Typography color="grays-mid">Not set</Typography>,
    }
  ),

  // impressions
  columnHelper.accessor((row) => row.impressions.toString(), {
    id: 'impressions',
    header: 'impressions',
  }),

  // displays on
  columnHelper.accessor('displaysOn', {
    header: 'displays on',
  }),
];
