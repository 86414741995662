import styled from '@emotion/styled';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Flex, SelectField, Typography } from '@jane/shared/reefer';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';
import type { WeekDayName } from './dailySchedule';

const TimeSlotSelectorWrapper = styled.div(({ theme }) => ({
  background: theme.colors.grays.ultralight,
  padding: '24px',
}));

const TIME_SLOT_OPTIONS = Array.from({ length: 24 }, (_, index) => {
  const hour = index % 12 || 12;
  const ampm = index < 12 ? 'AM' : 'PM';
  const timeLabel =
    index === 23
      ? '11:59 PM'
      : `${hour.toString().padStart(2, '0')}:00 ${ampm}`;
  return { label: timeLabel, value: timeLabel };
});

const parseTime = (timeString: string) => {
  return parse(timeString, 'hh:mm a', new Date());
};

export const TimeSlotSelector = ({ day }: { day: WeekDayName }) => {
  const {
    field: { onChange: onChangeSchedule, value: schedule },
  } = useController<FlatAdSubmissionForm, 'dayParting'>({
    name: 'dayParting',
  });

  const daySchedule = schedule[day];
  const onChangeDaySchedule = (newSchedule: {
    endTime: string;
    startTime: string;
  }) => {
    onChangeSchedule({ ...schedule, [day]: newSchedule });
  };

  const { setError, clearErrors } = useFormContext<FlatAdSubmissionForm>();
  const setDayPartError = (errorMessage: string) =>
    setError('dayParting', {
      type: 'custom',
      message: `Invalid ${day} schedule. ${errorMessage}`,
    });
  const clearDayPartError = () => clearErrors('dayParting');

  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');

  return (
    <TimeSlotSelectorWrapper>
      <Flex flexDirection="column" gap={24}>
        <Flex gap={24}>
          <Flex grow={1} flexDirection="column">
            <SelectField
              name={`startTime-${day}`}
              data-testid={`startTime-${day}`}
              label="Start time"
              defaultValue={daySchedule?.startTime || '12:00 AM'}
              options={TIME_SLOT_OPTIONS}
              onChange={(startTime) => {
                if (
                  !isBefore(
                    parseTime(startTime),
                    parseTime(daySchedule.endTime)
                  )
                ) {
                  setStartTimeError(
                    'Start time should be earlier than the end time'
                  );
                  setDayPartError(
                    'Start time should be earlier than the end time'
                  );
                } else {
                  setStartTimeError('');
                  clearDayPartError();
                }
                onChangeDaySchedule({ ...daySchedule, startTime: startTime });
              }}
              width="100%"
              required
            />
            {startTimeError && (
              <Typography color="error" mt={16} role="alert">
                {startTimeError}
              </Typography>
            )}
          </Flex>
          <Flex grow={1} flexDirection="column">
            <SelectField
              name={`endTime-${day}`}
              label="End time"
              defaultValue={daySchedule?.endTime || '11:59 PM'}
              onChange={(endTime) => {
                if (
                  isBefore(parseTime(endTime), parseTime(daySchedule.startTime))
                ) {
                  setEndTimeError('End time should be after start time');
                  setDayPartError('End time should be after start time');
                } else {
                  setEndTimeError('');
                  clearDayPartError();
                }
                onChangeDaySchedule({ ...daySchedule, endTime: endTime });
              }}
              options={TIME_SLOT_OPTIONS}
              width="100%"
              required
            />
            {endTimeError && (
              <Typography color="error" mt={16} role="alert">
                {endTimeError}
              </Typography>
            )}
          </Flex>
        </Flex>
        <Typography color="grays-mid">
          Configure specific times on specific days for this flight to serve.
          Times display in Pacific Time Zone.
        </Typography>
      </Flex>
    </TimeSlotSelectorWrapper>
  );
};
