import { z } from 'zod';

export const ApiProductBrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  logo_url: z.nullable(z.string()),
  normalized_name: z.nullable(z.string()),
});

export const ApiProductBrandsSchema = z.object({
  product_brands: z.array(ApiProductBrandSchema),
  has_next_page: z.boolean(),
});

export type ApiProductBrand = z.infer<typeof ApiProductBrandSchema>;
export type ApiProductBrands = z.infer<typeof ApiProductBrandsSchema>;

const ZonesSchema = z.array(
  z
    .enum([
      'Cart toppers',
      'Inline product',
      'Menu row',
      'Product page',
      'Recommended row',
    ])
    .or(z.enum(['Mixed']))
);

const StoresSchema = z.array(
  z.object({
    id: z.number(),
    description: z.string(),
  })
);

export const ApiFlightSummarySchema = z.object({
  id: z.number(),
  active: z.boolean(),
  bid: z.nullable(z.number()),
  dailyBudget: z.nullable(z.number()), // nullable on flat model
  displaysOn: z.nullable(z.enum(['All', 'Web', 'Kiosk', '-'])),
  endDate: z.nullable(z.string().datetime({ local: true })),
  excludedStores: StoresSchema,
  frequencyCapNumerator: z.nullable(z.number()),
  frequencyCapDenominator: z.nullable(z.number()),
  frequencyCapUnit: z.nullable(
    z.enum(['minutes', 'hours', 'days', 'disabled'])
  ),
  impressions: z.number(),
  keywords: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  model: z.enum(['cpc', 'cpm', 'flat']),
  segment: z.nullable(
    z.object({
      id: z.number(),
      description: z.string(),
    })
  ),
  selectedProducts: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  spend: z.number(),
  startDate: z.string().datetime({ local: true }),
  states: z.array(z.string()),
  status: z.enum([
    '-',
    'Ended',
    'Pending',
    'Healthy',
    'Borderline',
    'In Danger',
    'Finished',
    'Undelivered',
  ]),
  stores: StoresSchema,
  title: z.string().nullable(),
  zones: ZonesSchema,
});

// TODO: standardize the jam server API so this is consistent with the brands/{brand_id}/flights response
export const ApiFlightDetailsSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  bid: z.number(),
  budget: z.number(),
  displaysOn: z.string().nullable(),
  editedBy: z.object({ id: z.number(), email: z.string() }),
  end: z.string().datetime({ local: true }),
  excludedStores: z.array(
    z.object({ id: z.number(), description: z.string() })
  ),
  frequencyCap: z.object({
    frequency: z.number().nullable(),
    periodLength: z.number().nullable(),
    periodUnit: z.string(),
  }),
  keywords: z.array(z.object({ id: z.number(), name: z.string() })),
  priority: z.string().nullable(),
  schedule: z.array(
    z.object({
      startTime: z.string(),
      endTime: z.string(),
      weekDays: z.array(z.string()),
    })
  ),
  segment: z.object({ id: z.number(), description: z.string() }).nullable(),
  selectedProducts: z.array(z.object({ id: z.number(), name: z.string() })),
  start: z.string().datetime({ local: true }),
  states: z.array(z.string()).nullable(),
  status: z.enum([
    '-',
    'Ended',
    'Pending',
    'Healthy',
    'Borderline',
    'In Danger',
    'Finished',
    'Undelivered',
  ]),
  stores: z.array(z.object({ id: z.number(), description: z.string() })),
  title: z.string().nullable(),
  type: z.enum(['Flat', 'CPM', 'CPC']),
  updatedAt: z.string().datetime(),
  zones: ZonesSchema,
});

const ApiBrandStatisticsSchema = z.object({
  adsLive: z.number(),
  customersImpressed: z.number(),
  id: z.number(),
  impressions: z.optional(z.number()),
  roas: z.number(),
  totalBillable: z.number(),
});

export const ApiBrandAdsSchema = z.object({
  flights: z.array(ApiFlightSummarySchema),
  statistics: ApiBrandStatisticsSchema,
});

export type ApiBrandAds = z.infer<typeof ApiBrandAdsSchema>;
export type ApiFlightSummary = z.infer<typeof ApiFlightSummarySchema>;
export type ApiBrandStatistics = z.infer<typeof ApiBrandStatisticsSchema>;
export type ApiFlightDetails = z.infer<typeof ApiFlightDetailsSchema>;
