import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import type { Brand } from '@jane/catalog-cms/data-access';
import {
  Box,
  Button,
  Modal,
  SearchField,
  Typography,
} from '@jane/shared/reefer';

import { BrandCheckboxRow } from './BrandCheckboxRow';

export interface SelectBrandInputModalProps {
  brands: Brand[];
  isFetchingNextPage: boolean;
  onChange: (selectedBrands: Brand[]) => void;
  onModalClose: () => void;
  onNextPage: () => void;
  onSearchChange: (query: string) => void;
  selectedBrands: Brand[];
}

export const SelectBrandInputModal = ({
  brands,
  selectedBrands,
  onChange,
  onSearchChange,
  isFetchingNextPage,
  onNextPage,
  onModalClose,
}: SelectBrandInputModalProps) => {
  const viewAllBrands = () => {
    onSearchChange('');
    onChange([]);
    onModalClose();
  };

  const wrapperRef = useRef(null);
  const { ref } = useInView({
    root: wrapperRef.current,
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && !isFetchingNextPage) {
        onNextPage();
      }
    },
  });

  const select = (brand: Brand) => {
    // We are de-selecting a brand, so we filter it out
    const newSelectedBrands = selectedBrands.filter(
      (selectedBrand) => selectedBrand.id !== brand.id
    );

    // We are selecting a brand since our newly selected brand was not found in the filter operation and our lenghts remain the same
    if (selectedBrands.length === newSelectedBrands.length) {
      newSelectedBrands.push(brand);
    }

    onChange(newSelectedBrands);
  };

  const isSelected = (brand: Brand) => {
    return selectedBrands.some(
      (selectedBrand) => selectedBrand.id === brand.id
    );
  };

  return (
    <>
      <Modal.Header
        title="Select brands"
        actions={
          <Button
            variant="secondary"
            label="View all brands"
            onClick={viewAllBrands}
          />
        }
      />
      <Modal.Content ref={wrapperRef}>
        <SearchField
          borderRadius="sm"
          label="Search Brands"
          name="brand-search-field"
          onChange={onSearchChange}
          placeholder="Search for a brand"
          mb={24}
        />
        {selectedBrands.length > 0 && (
          <>
            <Typography variant="body-bold" mb={16}>
              Selected
            </Typography>

            <Box background="grays-ultralight" borderRadius="lg">
              {selectedBrands.map((brand, i) => (
                <BrandCheckboxRow
                  key={brand.id}
                  brand={brand}
                  onClick={() => select(brand)}
                  selected={true}
                  data-testid={`select-brand-modal-selected-${brand.id}`}
                  divider={i > 0}
                />
              ))}
            </Box>

            {/* expand divider to cover modal */}
            <Modal.ContentDivider />
          </>
        )}

        {brands?.map((brand, i) => (
          <BrandCheckboxRow
            key={brand.id}
            brand={brand}
            onClick={() => select(brand)}
            selected={isSelected(brand)}
            data-testid={`select-brand-modal-${brand.id}`}
            divider={i > 0}
            ref={i === brands.length - 1 ? ref : undefined}
          />
        ))}
      </Modal.Content>
    </>
  );
};
