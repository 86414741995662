import styled from '@emotion/styled';

import { useMediaFormUtil } from '@jane/ad-manager/hooks';
import { Image } from '@jane/shared/reefer';

import { PlaybackIcon, size as iconSize } from './playbackIcon';

const height = 96;
const width = 116;

const BrandThemeMediaItemWrapper = styled.div<{
  disabled: boolean;
}>(({ disabled, theme }) => ({
  borderRadius: theme.borderRadius.lg,
  height: height,
  width: width,
  img: {
    borderRadius: theme.borderRadius.sm,
    opacity: disabled ? '25%' : '100%',
  },
}));

// CU-85zryw22p: using this component instead of outline+border-radius allows for rounded corners in safari
const SelectedBorder = styled.div<{ selected: boolean }>(
  ({ selected, theme }) => ({
    position: 'relative',
    top: -height,
    height: height,
    width: width,
    borderColor: selected ? theme.colors.grays.black : 'transparent',
    borderRadius: theme.borderRadius.sm,
    borderStyle: 'solid',
    borderWidth: 4,
  })
);

const IconWrapper = styled.div(() => ({
  position: 'relative',
  left: 'calc(50% - 48px/2)',
  top: `calc(-2 * ${height}px + ${iconSize / 2}px)`,
}));

interface Props {
  disabled: boolean;
  mediaKey: string;
  onMediaClick: () => void;
  selected: boolean;
}
export const MediaItem = ({
  disabled,
  mediaKey,
  onMediaClick,
  selected,
}: Props) => {
  const { getMediaType, getVideoOverlayImageUrl, getImageImageUrl } =
    useMediaFormUtil();
  const isAVideo = getMediaType(mediaKey) === 'video';
  const overlayImageUrl = getVideoOverlayImageUrl(mediaKey);
  const imageUrl = getImageImageUrl(mediaKey);
  const imgSrc = isAVideo ? overlayImageUrl : imageUrl;
  return (
    <BrandThemeMediaItemWrapper
      disabled={disabled}
      onClick={() => !disabled && onMediaClick()}
    >
      <Image
        key={imgSrc}
        src={imgSrc}
        altText="brand name image"
        objectFit="cover"
        height="100%"
      />
      <SelectedBorder selected={selected} />
      {isAVideo && (
        <IconWrapper>
          <PlaybackIcon />
        </IconWrapper>
      )}
    </BrandThemeMediaItemWrapper>
  );
};
