import styled from '@emotion/styled';

import { Box, Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { FlightDetailSection } from './flightDetailSection';

interface ManualDaySelection {
  name: string;
  value: string;
}
interface ScheduleDetailsProps {
  end: string;
  frequencyCapping: {
    frequency: string;
    periodLength: string;
    periodUnit: string;
  };
  spendAllocation:
    | {
        mode: 'consistent';
      }
    | {
        manualDays: ManualDaySelection[];
        mode: 'manual';
      };
  start: string;
}

const StyledTable = styled.table(({ theme }) => ({
  borderSpacing: 0,
  width: '100%',
  whiteSpace: 'nowrap',
  'th:first-of-type, td:first-of-type': {
    position: 'sticky',
    left: 0,
  },
  'td:nth-of-type(2), th:nth-of-type(2)': {
    paddingLeft: '24px',
  },
}));

interface ManualSelectionDaysProps {
  days: ManualDaySelection[];
}
const ManualSelectionDays = ({ days }: ManualSelectionDaysProps) => (
  <StyledTable>
    <Table.Head>
      <Table.Row>
        <Table.HeaderCell width="50%">
          <Typography variant="caps">Day</Typography>
        </Table.HeaderCell>
        <Table.HeaderCell width="50%">
          <Typography variant="caps">Schedule</Typography>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Head>
    <Table.Body>
      {days?.map(({ name, value }) => (
        <Table.Row key={name}>
          <Table.Cell width="50%">
            <Typography>{name}</Typography>
          </Table.Cell>
          <Table.Cell width="50%">
            <Typography>{value}</Typography>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </StyledTable>
);
export const ScheduleDetails = ({
  frequencyCapping: { frequency, periodLength, periodUnit },
  spendAllocation,
  start,
  end,
}: ScheduleDetailsProps) => {
  const spendAllocationMode =
    spendAllocation.mode === 'consistent' ? (
      'Consistent schedule throughout the week'
    ) : (
      <>
        <Typography>Manually select days</Typography>
        <Box mt={24}>
          <ManualSelectionDays days={spendAllocation.manualDays} />
        </Box>
      </>
    );

  return (
    <FlightDetailSection
      data-testid="schedule-details"
      headerLabel="Frequency capping and schedule"
      rows={[
        [
          {
            name: 'Frequency Capping',
            value: `For each user, display this flight up to ${frequency} times every ${periodLength} ${periodUnit}.`,
          },
          { name: 'Dates', value: `${start} – ${end}` },
        ],
        [
          {
            name: 'Spend allocation',
            value: spendAllocationMode,
          },
        ],
      ]}
    />
  );
};
