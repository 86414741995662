import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import type * as ReactTable from '@tanstack/react-table';

import { brandServerApi } from '../brandServerApi';
import type {
  FetchProductFiltersResponse,
  FetchProductsResponse,
} from './types';
import {
  FetchProductFiltersResponseSchema,
  FetchProductsResponseSchema,
} from './types';

interface UseFetchProductsParams {
  brandId?: number;
  categories?: string[];
  ids?: string[];
  kinds?: string[];
  name?: string;
  order: ReactTable.ColumnSort;
  per_page?: number;
  root_subtypes?: string[];
}

export const DEFAULT_PRODUCTS_PER_PAGE = 25;

export const FETCH_PRODUCTS_URL = '/jam/products/search';
export const FETCH_PRODUCT_FILTERS_URL = '/jam/products/filters';

export const useFetchProducts = (params: UseFetchProductsParams) =>
  useInfiniteQuery<FetchProductsResponse>({
    queryKey: ['products', params],
    enabled: !!params.brandId,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 1 }) => {
      const {
        brandId,
        categories,
        ids,
        kinds,
        name,
        root_subtypes,
        order,
        per_page = DEFAULT_PRODUCTS_PER_PAGE,
      } = params;

      const body = {
        categories,
        kinds,
        name,
        ids,
        order: order && `${order.id} ${order.desc ? 'desc' : 'asc'}`,
        page: pageParam,
        per_page,
        product_brand_id: brandId,
        root_subtypes,
      };

      const res = await brandServerApi.post<FetchProductsResponse>(
        FETCH_PRODUCTS_URL,
        body
      );

      FetchProductsResponseSchema.parse(res);
      return res;
    },
    getNextPageParam: ({ meta }) =>
      meta.page < meta.number_of_pages ? meta.page + 1 : undefined,
  });

interface UseFetchProductFiltersParams {
  brandId: number | undefined;
}

export const useFetchProductFilters = ({
  brandId,
}: UseFetchProductFiltersParams) =>
  useQuery<FetchProductFiltersResponse>({
    queryKey: ['product_filters', brandId],
    enabled: !!brandId,
    queryFn: async () => {
      const urlParams = new URLSearchParams();
      if (brandId) urlParams.set('product_brand_id', brandId.toString());
      const res = await brandServerApi.get<FetchProductFiltersResponse>(
        `${FETCH_PRODUCT_FILTERS_URL}?${urlParams}`
      );
      FetchProductFiltersResponseSchema.parse(res);
      return res;
    },
    retry: false,
  });
