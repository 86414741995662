import styled from '@emotion/styled';
import { useCallback, useRef, useState } from 'react';

import { useMediaForm, useMediaFormUtil } from '@jane/ad-manager/hooks';
import { NEW_IMAGE, NEW_VIDEO } from '@jane/ad-manager/types';
import type {
  BrandMediaTagWithMediaCompliances,
  CustomMedia,
} from '@jane/ad-manager/types';
import { SortableList } from '@jane/shared/components';
import type { SortableItem } from '@jane/shared/components';
import {
  Flex,
  PhotosIcon,
  PlayIcon,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import { ImageComplianceSection } from './imageComplianceSection';
import { MaybeHidden } from './maybeHidden';
import { MediaItem } from './mediaItem';
import { MediaPreview } from './mediaPreview';
import { MediaUpload } from './mediaUpload';

const ScrollWrapper = styled.div(({ theme }) => ({
  scrollMargin: 40,
  display: 'flex',
  flexDirection: 'column',
}));

const AddMediaButton = styled.div<{ disabled: boolean; selected: boolean }>(
  ({ disabled, theme, selected }) => ({
    minWidth: 116,
    height: 96,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.grays.ultralight,
    borderColor: selected ? theme.colors.grays.black : theme.colors.grays.light,
    borderRadius: theme.borderRadius.sm,
    borderStyle: 'solid',
    borderWidth: selected ? 4 : 1,
    cursor: disabled ? 'default' : 'pointer',
  })
);

const GrayBackground = styled.div<{ isTablet: boolean }>(
  ({ isTablet, theme }) => {
    const padding = isTablet ? 40 : 24;
    return {
      backgroundColor: theme.colors.grays.ultralight,
      marginLeft: -padding,
      paddingLeft: padding,
      marginRight: -padding,
      paddingRight: padding,
      marginBottom: -padding,
      paddingBottom: padding,
    };
  }
);

interface Props {
  customMedia: CustomMedia[];
  isSaving: boolean;
  isSelected: boolean;
  mediaTags: BrandMediaTagWithMediaCompliances[];
  setError: (error: string) => void;
  setIsSaving: (isSaving: boolean) => void;
}
export const MediaEditor = ({
  customMedia: allMedia,
  isSaving,
  isSelected,
  setIsSaving,
  setError,
  mediaTags,
}: Props) => {
  const {
    addCustomImage,
    addCustomVideo,
    allMediaKeys,
    deleteMedia,
    selectedMediaKey,
    setSelectedMediaKey,
    shownMediaKeys,
    updateMediaPositions,
    updateMediaTags,
  } = useMediaForm(allMedia);
  const { getDestroy, getMediaType } = useMediaFormUtil();

  const isTablet = useTabletMediaQuery({});
  const titleRef = useRef<HTMLDivElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const isLoading = isSaving || isUploading;

  const onUploading = useCallback(
    (value: boolean) => {
      setIsSaving(value);
      setIsUploading(value);
    },
    [setIsSaving]
  );

  const selectMedia = (selectedMediaKey: string) => {
    setSelectedMediaKey(selectedMediaKey);
    if (titleRef.current) titleRef.current.scrollIntoView(true);
  };

  const showAddImage =
    shownMediaKeys.filter((mediaKey) => getMediaType(mediaKey) === 'image')
      .length < 5;
  const showAddVideo =
    shownMediaKeys.filter((mediaKey) => getMediaType(mediaKey) === 'video')
      .length < 1;

  const onReorder = (items: SortableItem<CustomMedia>[]) => {
    const allMediaKeys = items.map((item) => item.id);
    updateMediaPositions(allMediaKeys);
  };

  return (
    <MaybeHidden isHidden={!isSelected}>
      <ScrollWrapper ref={titleRef}>
        <Flex flexDirection="column">
          <Flex flexDirection="column" mb={24}>
            <Flex flexDirection="column">
              <Typography>Assets</Typography>
              <Typography color="grays-mid">
                Click and drag to change the order of your media.
              </Typography>
            </Flex>
            <Flex mt={16} gap={24} flexWrap="wrap">
              <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <SortableList
                  disabled={isLoading}
                  items={allMediaKeys.map((mediaKey) => ({ id: mediaKey }))}
                  onChange={onReorder}
                  hideItem={({ id }) => getDestroy(id) || false}
                  renderItem={({ id }) => (
                    <MediaItem
                      disabled={isLoading}
                      mediaKey={id}
                      selected={selectedMediaKey === id}
                      onMediaClick={() => selectMedia(id)}
                    />
                  )}
                />
              </div>

              {showAddImage && (
                <AddMediaButton
                  disabled={isLoading}
                  selected={selectedMediaKey === NEW_IMAGE}
                  onClick={() => !isLoading && selectMedia(NEW_IMAGE)}
                >
                  <Flex flexDirection="column" alignItems="center" gap={8}>
                    <PhotosIcon
                      color={isLoading ? 'grays-light' : 'grays-black'}
                    />
                    <Typography
                      color={isLoading ? 'grays-light' : 'grays-black'}
                      variant="mini-bold"
                    >
                      Add photo
                    </Typography>
                  </Flex>
                </AddMediaButton>
              )}

              {showAddVideo && (
                <AddMediaButton
                  disabled={isLoading}
                  selected={selectedMediaKey === NEW_VIDEO}
                  onClick={() => !isLoading && selectMedia(NEW_VIDEO)}
                >
                  <Flex flexDirection="column" alignItems="center" gap={8}>
                    <PlayIcon
                      color={isLoading ? 'grays-light' : 'grays-black'}
                    />
                    <Typography
                      color={isLoading ? 'grays-light' : 'grays-black'}
                      variant="mini-bold"
                    >
                      Add video
                    </Typography>
                  </Flex>
                </AddMediaButton>
              )}
            </Flex>
          </Flex>
          <GrayBackground isTablet={isTablet}>
            {allMediaKeys.map((mediaKey) => (
              <MediaPreview
                key={mediaKey}
                mediaKey={mediaKey}
                deleteMedia={deleteMedia}
                isSaving={isSaving}
                isSelected={selectedMediaKey === mediaKey}
                isUploading={isUploading}
                setIsUploading={onUploading}
                setError={setError}
              />
            ))}
            {selectedMediaKey === NEW_IMAGE && (
              <MediaUpload
                newAssetType="newImage"
                addCustomImage={addCustomImage}
                addCustomVideo={addCustomVideo}
                setError={setError}
                isUploading={isUploading}
                setIsUploading={onUploading}
              />
            )}
            {selectedMediaKey === NEW_VIDEO && (
              <MediaUpload
                newAssetType="newVideo"
                addCustomImage={addCustomImage}
                addCustomVideo={addCustomVideo}
                setError={setError}
                isUploading={isUploading}
                setIsUploading={onUploading}
              />
            )}
          </GrayBackground>
        </Flex>
        {allMediaKeys.map((mediaKey) => (
          <ImageComplianceSection
            key={mediaKey}
            isSelected={selectedMediaKey === mediaKey}
            mediaKey={mediaKey}
            updateMediaTags={updateMediaTags}
            mediaTagOptions={mediaTags}
          />
        ))}
      </ScrollWrapper>
    </MaybeHidden>
  );
};
