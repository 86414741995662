import styled from '@emotion/styled';
import type * as ReactTable from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import type { PropsWithChildren } from 'react';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import { Box, Loading, Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

interface FlightsTableProps {
  flightsTable: ReactTable.Table<ApiFlightSummary>;
  isLoading: boolean;
}

const FlightColumnHeaderCell = ({ children }: PropsWithChildren) => {
  return (
    <Table.HeaderCell>
      <Box pl={20}>
        <Typography variant="caps">{children}</Typography>
      </Box>
    </Table.HeaderCell>
  );
};

const FlightsTableRow = ({
  row,
}: {
  row: ReactTable.Row<ApiFlightSummary>;
}) => (
  <Table.Row>
    {row
      .getVisibleCells()
      .map((cell: ReactTable.Cell<ApiFlightSummary, unknown>) => (
        <Table.Cell
          key={cell.id}
          data-testid={`flight-${row.original.id}-${cell.column.id}`}
        >
          <Box pl={20}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Box>
        </Table.Cell>
      ))}
  </Table.Row>
);

const StyledBox = styled(Box)({
  scrollbarWidth: 'none',
});

export const FlightsTable = ({
  flightsTable,
  isLoading,
}: FlightsTableProps) => {
  const headersToRender = flightsTable
    .getFlatHeaders()
    .filter((header) => header.column.getIsVisible());

  return (
    <StyledBox overflow="scroll">
      <Table
        minWidth="100%"
        style={{ paddingBottom: 240 }}
        data-testid="flights-table"
      >
        <Table.Head>
          <Table.Row>
            {headersToRender.map((header) => (
              <FlightColumnHeaderCell key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </FlightColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <Box mt={128}>
              <Loading />
            </Box>
          ) : (
            flightsTable
              .getCoreRowModel()
              .rows.map((row) => <FlightsTableRow key={row.id} row={row} />)
          )}
        </Table.Body>
      </Table>
    </StyledBox>
  );
};
