/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ArrowLeft24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11706_30023)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60457 4.28795C8.99782 4.67572 9.00227 5.30887 8.61449 5.70213L3.39046 11L23 11C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13L3.39046 13L8.61449 18.2979C9.00227 18.6911 8.99782 19.3243 8.60457 19.7121C8.21131 20.0998 7.57816 20.0954 7.19039 19.7021L0.287949 12.7021C-0.0959829 12.3128 -0.0959829 11.6872 0.287949 11.2979L7.19039 4.29787C7.57816 3.90462 8.21131 3.90017 8.60457 4.28795Z"
        fill="#0E0E0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_11706_30023">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: ArrowLeft24,
  sm: ArrowLeft24,
  lg: ArrowLeft24,
  xl: ArrowLeft24,
  xxl: ArrowLeft24,
  xxxl: ArrowLeft24,
};

export const ArrowLeftIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'arrow-left-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
