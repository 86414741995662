import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import type { ApiFlightSummary } from '../productBrands';

interface DailySchedule {
  endTime: string;
  startTime: string;
}

interface ApiDayPart {
  end_time: string;
  start_time: string;
  weekdays: string[];
}

const formatDayPartTime = (selectedTime: string): string => {
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
  return dayjs(selectedTime, 'hh:mm A').format('HH:mm:ss');
};

export const transformDayParts = (
  days: Record<string, DailySchedule>
): ApiDayPart[] => {
  const result: ApiDayPart[] = [];

  // group days with identical start and end times
  const groupedDays: Record<string, string[]> = {};

  for (const [day, params] of Object.entries(days)) {
    const startTime = params.startTime;
    const endTime = params.endTime;
    const dayAbbreviation = day.substring(0, 2).toUpperCase();

    // check if a group with identical start and end times already exists
    const key = `${startTime}-${endTime}`;
    if (!(key in groupedDays)) {
      groupedDays[key] = [];
    }

    groupedDays[key].push(dayAbbreviation);
  }

  // convert the grouped days into the required format for the backend
  for (const [timeKey, days] of Object.entries(groupedDays)) {
    const [startTime, endTime] = timeKey.split('-');
    const backendParam: ApiDayPart = {
      start_time: formatDayPartTime(startTime),
      end_time: formatDayPartTime(endTime),
      weekdays: days,
    };
    result.push(backendParam);
  }

  return result;
};

export const convertIdsToNumber = (ids: string[]): number[] =>
  ids.map((id) => Number(id));

export const zoneToPlatforms = (selectedPlatform: string): string[] => {
  if (selectedPlatform === 'all-platforms') return ['web', 'kiosk'];
  return [selectedPlatform];
};

const FREQ_UNIT_MAP: Record<string, 0 | 1 | 2 | 3> = {
  disabled: 0,
  hours: 1,
  days: 2,
  minutes: 3,
};

export const toFrequencyUnitCode = (
  selectedUnit: ApiFlightSummary['frequencyCapUnit']
): 0 | 1 | 2 | 3 => {
  if (!selectedUnit) return 0;
  return FREQ_UNIT_MAP[selectedUnit];
};

const PLACEMENTS_TO_ZONES_MAP: Record<string, string> = {
  'Menu row': 'storeMenu',
  'Inline product': 'storeMenuInline',
  'Product page': 'storePDP',
  'Cart toppers': 'cart',
  'Recommended row': 'magicRow',
};

export const placementsToZones = (selectedPlacements: string[]): string[] =>
  selectedPlacements.map((placement) => PLACEMENTS_TO_ZONES_MAP[placement]);
