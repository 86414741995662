import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  useGetFlightDetail,
  useUpdateFlight,
} from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import type { DayPart } from '@jane/ad-manager/types';
import {
  dayAbbreviationFromIndex,
  dayNameFromIndex,
  parsePacificTime,
  parseUTCDateTimeInPT,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Loading,
  Tag,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { useNavigateToDigitalMerchandising } from './DigitalMerchandising';
import { buildDuplicateFlightDetailsFormData } from './adBuilder/useAdBuilderForm';
import {
  PricingDetails,
  ProductDetails,
  ScheduleDetails,
  TargetingDetails,
  TypeDetails,
} from './flightDetails';

export const FlightDetail = () => {
  const { id } = useParams();
  const toast = useToast();
  const flightId = Number(id) || undefined;

  const { data: getFlightResult, isLoading } = useGetFlightDetail(
    flightId ? { flightId } : undefined
  );

  const {
    data: updatedFlight,
    isLoading: isActiveToggleLoading,
    mutate: mutateFlight,
    error: updateError,
    isError: isUpdateError,
  } = useUpdateFlight();
  useEffect(() => {
    if (isUpdateError) {
      toast.add({
        label: `Failed to update flight: ${updateError.message}`,
        variant: 'error',
      });
    }
  });
  const handleClickActiveToggle = () => {
    if (flightId === undefined) {
      return;
    }
    mutateFlight({ id: flightId, is_active: !flight?.active });
  };
  const flight = updatedFlight || getFlightResult;

  const storeDisplayFromEntry = ({
    description,
    id,
  }: {
    description: string;
    id: number;
  }) => ({
    id: id.toString(),
    name: description,
  });

  const formatISODate = (isoDate: string) =>
    parseUTCDateTimeInPT(isoDate).format('M/D/YYYY');

  const timeFormat = 'h:mm a';

  const START_OF_DAY = '00:00:00';
  const END_OF_DAY = '23:59:00';
  const isDayPartAllDay = (dayPart: DayPart) =>
    dayPart.startTime === START_OF_DAY &&
    (dayPart.endTime === START_OF_DAY || dayPart.endTime === END_OF_DAY);

  const dayParts = flight?.schedule;
  const manualDays =
    dayParts && dayParts.length > 0
      ? [1, 2, 3, 4, 5, 6, 0] // start with monday, end with sunday
          .map((dayIndex) => {
            const dayName = dayNameFromIndex(dayIndex);
            const dayAbbreviation = dayAbbreviationFromIndex(dayIndex);
            const scheduleForDay = dayParts.find((dayPart) =>
              dayPart.weekDays.includes(dayAbbreviation)
            );
            if (!scheduleForDay) {
              return undefined;
            }
            if (isDayPartAllDay(scheduleForDay)) {
              return { name: dayName, value: 'All day' };
            }
            return {
              name: dayName,
              value: `${parsePacificTime(scheduleForDay.startTime).format(
                timeFormat
              )} - ${parsePacificTime(scheduleForDay.endTime).format(
                timeFormat
              )}`,
            };
          })
          .filter((item): item is { name: string; value: string } => !!item)
      : undefined;
  const { selectedBrand } = useSelectedBrandContext();
  const navigateToDigitalMerchandising = useNavigateToDigitalMerchandising();

  const onDuplicate = () => {
    if (!flight) return;

    const formData = buildDuplicateFlightDetailsFormData(flight, selectedBrand);
    navigateToDigitalMerchandising({ adBuilderPrefill: formData });
  };

  return (
    <>
      {isLoading && <Loading color="purple" />}
      {flight && (
        <Flex flexDirection="column" gap={24} mx={40} mb={40}>
          <Box mt={40}>
            <Breadcrumbs>
              <Link to={brandsPaths.digitalMerchandising()}>All flights</Link>
              <Typography>Flight {flightId}</Typography>
            </Breadcrumbs>
          </Box>
          <Flex mt={40}>
            <Box>
              <Flex alignItems="center">
                <Typography variant="header-bold" as="h1">
                  Flight {flightId}
                </Typography>
                <Box ml={8}>
                  {flight.active && (
                    <Tag background="palm-light" color="palm" label="Active" />
                  )}
                  {!flight.active && (
                    <Tag
                      background="grays-light"
                      color="grays-dark"
                      label="Inactive"
                    />
                  )}
                </Box>
              </Flex>
              <Typography data-testid="header-flight-dates" color="text-light">
                {formatISODate(flight.start)} – {formatISODate(flight.end)}
              </Typography>
              <Typography color="text-light">
                Last updated by {flight.editedBy.email} on{' '}
                {formatISODate(flight.updatedAt)}
              </Typography>
            </Box>
            <Flex ml="auto">
              <Button
                onClick={() => handleClickActiveToggle()}
                loading={isActiveToggleLoading}
                variant="secondary"
                label={flight.active ? 'Deactivate' : 'Activate'}
              />
              <Button
                variant="secondary"
                label="Duplicate"
                onClick={() => onDuplicate()}
                ml={16}
              />
            </Flex>
          </Flex>
          <TypeDetails
            placements={flight.zones}
            displaysOn={flight.displaysOn || ''}
            model={flight.type}
            menuRowTitle={flight.title || undefined}
          />
          <ProductDetails
            {...(flight.keywords && flight.keywords.length > 0
              ? {
                  mode: 'category',
                  categories: flight.keywords.map((keyword) => keyword.name),
                }
              : flight.selectedProducts && flight.selectedProducts.length > 0
              ? {
                  mode: 'manual',
                  productNames: flight.selectedProducts.map(({ name }) => name),
                }
              : { mode: 'auto' })}
          />
          {flight.priority === 'premium' && (
            <>
              <PricingDetails priority totalBudget={`$${flight.bid}`} />
              <TargetingDetails
                priorityEnabled
                stores={flight.stores.map(storeDisplayFromEntry)}
              />
            </>
          )}
          {flight.priority === 'auction' && (
            <>
              <PricingDetails
                dailyBudget={`$${flight.budget}`}
                cpmBid={`$${flight.bid}`}
              />
              <TargetingDetails
                segmentCategory={flight.segment?.description || ''}
                states={flight.states || []}
                stores={flight.stores.map(storeDisplayFromEntry)}
                storesExcluded={flight.excludedStores.map(
                  storeDisplayFromEntry
                )}
              />
            </>
          )}
          <ScheduleDetails
            frequencyCapping={{
              frequency: `${flight.frequencyCap.frequency}`,
              periodLength: `${flight.frequencyCap.periodLength}`,
              periodUnit: flight.frequencyCap.periodUnit,
            }}
            spendAllocation={
              !manualDays || manualDays.length === 0
                ? { mode: 'consistent' } // empty array === consistent schedule
                : { mode: 'manual', manualDays }
            }
            start={formatISODate(flight.start)}
            end={formatISODate(flight.end)}
          />
        </Flex>
      )}
    </>
  );
};
