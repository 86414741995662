import { useController, useFormContext } from 'react-hook-form';

import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Flex } from '@jane/shared/reefer';

import type {
  AdType,
  CPCAdPlacement,
  FlatAdSubmissionForm,
} from '../useAdBuilderForm';
import {
  DEFAULT_PROGRAMMATIC_CPC_IMPRESSIONS,
  DEFAULT_PROGRAMMATIC_CPC_TIME_PERIOD,
  defaultAdSubmissionFormData,
} from '../useAdBuilderForm';
import { ProductGroupOption } from './productGroupOption';

export const Type = () => {
  const { reset } = useFormContext<FlatAdSubmissionForm>();
  const { selectedBrand } = useSelectedBrandContext();
  const {
    field: { onChange, value: adType },
  } = useController({ name: 'adType' });

  const handleAdTypeChange = (adType: AdType) => {
    if (adType === 'cpm') {
      reset({
        ...defaultAdSubmissionFormData,
        menuRowTitle: selectedBrand?.name || '',
      });
    }
    if (adType === 'cpc') {
      reset({
        ...defaultAdSubmissionFormData,
        adType: 'cpc' as AdType,
        adPlacement: [
          'Recommended row',
          'Cart toppers',
          'Inline product',
        ] as CPCAdPlacement[],
        impressions: DEFAULT_PROGRAMMATIC_CPC_IMPRESSIONS,
        timePeriod: DEFAULT_PROGRAMMATIC_CPC_TIME_PERIOD,
        menuRowTitle: selectedBrand?.name || '',
      });
      onChange(adType);
    }
  };

  return (
    <Flex gap={16} justifyContent="center">
      <ProductGroupOption
        adType="cpm"
        isSelected={adType === 'cpm'}
        onClick={() => handleAdTypeChange('cpm')}
      />
      <ProductGroupOption
        adType="cpc"
        isSelected={adType === 'cpc'}
        onClick={() => handleAdTypeChange('cpc')}
      />
    </Flex>
  );
};
