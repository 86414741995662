import {
  getJaneGoldFAQPath,
  getJaneGoldLandingPath,
} from '@jane/shared-ecomm/util';
import { Link, Typography } from '@jane/shared/reefer';

import type { JaneGoldLearnMoreLinkProps } from '../janeGoldCallout.types';

export const JaneGoldLearnMoreLink = ({
  finePrint = false,
  userHasLinkedBank,
}: JaneGoldLearnMoreLinkProps) => {
  const learnMoreLink = userHasLinkedBank
    ? getJaneGoldFAQPath()
    : getJaneGoldLandingPath();

  return (
    <Link
      href={learnMoreLink}
      data-testid="learn-more-link"
      color={finePrint ? 'text-light' : 'text-main'}
    >
      <Typography
        as="span"
        variant={finePrint ? 'mini' : 'body'}
        color={finePrint ? 'text-light' : 'text-main'}
      >
        Learn more
      </Typography>
    </Link>
  );
};
