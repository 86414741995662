import { Suspense } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';

import { AdBuilderPage } from '@jane/ad-manager/pages';
import { SelectedBrandProvider } from '@jane/ad-manager/providers';
import { AnalyticsError } from '@jane/brands-analytics/components';
import {
  BrandPortalLayout,
  ExternalIdentityManager,
  Loading,
  RedirectAuthed,
  RedirectIf,
  RenderIfAuthorized,
  RequireAuth,
} from '@jane/brands/components';
import { FLAGS } from '@jane/brands/util';
import {
  LocalizationError,
  ProductDrawerLayout,
  ProductError,
  ProductsList,
} from '@jane/catalog-cms/components';
import { useFlag } from '@jane/shared/feature-flags';
import { load } from '@jane/shared/util';

const Login = load(() => import('./pages/Login'));
const ForgotPassword = load(() => import('./pages/ForgotPassword'));
const ResetPassword = load(() => import('./pages/ResetPassword'));
const Error = load(() => import('./pages/Error'));
const Products = load(() => import('./pages/CatalogCMS/Products'));
const EditProduct = load(() => import('./pages/CatalogCMS/EditProduct'));
const CreateProductNotice = load(
  () => import('./pages/CatalogCMS/CreateProductNotice')
);
const CreateLocalization = load(
  () => import('./pages/CatalogCMS/CreateLocalization')
);
const EditLocalization = load(
  () => import('./pages/CatalogCMS/EditLocalization')
);
const AnalyticsPage = load(() => import('./pages/Analytics/Analytics'));
const BrandThemeEditor = load(
  () => import('./pages/AdManager/BrandThemeEditor')
);
const BrandThemes = load(() => import('./pages/AdManager/BrandThemes'));
const DigitalMerchandising = load(
  () => import('./pages/AdManager/DigitalMerchandising')
);
const FlightDetail = load(() => import('./pages/AdManager/FlightDetail'));

/**
 * Component to gate the flight creation page. Remove after old implementation is cleaned up
 **/
const GateJamCreateFlight = () => {
  const isAllowed = useFlag(FLAGS.jamDedicatedFlightCreationRoute, false);

  return (
    <RedirectIf condition={!isAllowed}>
      <AdBuilderPage />
    </RedirectIf>
  );
};

export const routes = createRoutesFromElements([
  <Route element={<ExternalIdentityManager />} errorElement={<Error />}>
    <Route path="*" element={<Navigate to={'/login'} />} />

    <Route // Public routes that should redirect a logged in user
      element={<RedirectAuthed />}
    >
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loading fullPage />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Suspense fallback={<Loading fullPage />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path="/reset-password"
        element={
          <Suspense fallback={<Loading fullPage />}>
            <ResetPassword />
          </Suspense>
        }
      />
    </Route>

    <Route // Protected routes that require an authenticated user
      element={<RequireAuth />}
    >
      <Route element={<BrandPortalLayout />}>
        {/* Catalog CMS Routes */}
        <Route
          path={'/products'}
          element={
            <Suspense fallback={<Loading />}>
              <Products />
            </Suspense>
          }
          errorElement={<Error />}
        >
          <Route
            path="/products"
            element={
              <>
                <ProductsList />
                <Outlet />
              </>
            }
            errorElement={<Error />}
          >
            <Route element={<ProductDrawerLayout />}>
              <Route
                path=":productId"
                element={
                  <Suspense fallback={<Loading />}>
                    <EditProduct />
                  </Suspense>
                }
                errorElement={<ProductError />}
              />
              <Route
                path=":productId/report"
                element={
                  <Suspense fallback={<Loading />}>
                    <CreateProductNotice />
                  </Suspense>
                }
                errorElement={<ProductError />}
              />
              <Route
                path=":productId/localizations/:localizationId"
                element={
                  <Suspense fallback={<Loading />}>
                    <EditLocalization />
                  </Suspense>
                }
                errorElement={<LocalizationError />}
              />
              <Route
                path=":productId/localizations/new"
                element={
                  <Suspense fallback={<Loading />}>
                    <CreateLocalization />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* Analytics Routes */}
        <Route
          element={
            <Suspense fallback={<Loading />}>
              <RenderIfAuthorized
                requiredPermission="accessAnalytics"
                fallback={<Navigate to={'/products'} replace />}
              >
                <AnalyticsPage />
              </RenderIfAuthorized>
            </Suspense>
          }
          path="/analytics"
          errorElement={<AnalyticsError />}
        />
        <Route
          element={
            <Suspense fallback={<Loading />}>
              <RenderIfAuthorized
                requiredPermission="accessAnalytics"
                fallback={<Navigate to={'/products'} replace />}
              >
                <AnalyticsPage />
              </RenderIfAuthorized>
            </Suspense>
          }
          path="/analytics/:liveboardId"
          errorElement={<AnalyticsError />}
        />
      </Route>

      {/* Ad-Manager Routes */}
      <Route
        element={
          <SelectedBrandProvider>
            <Outlet />
          </SelectedBrandProvider>
        }
      >
        <Route element={<BrandPortalLayout />}>
          <Route
            path="/brand-themes"
            element={
              <RenderIfAuthorized
                requiredPermission="accessBrandPages"
                fallback={<Navigate to={'/products'} replace />}
              >
                <Suspense fallback={<Loading />}>
                  <BrandThemes />
                </Suspense>
              </RenderIfAuthorized>
            }
          />
          <Route
            path="/digital-merchandising"
            element={
              <RenderIfAuthorized
                requiredPermission="accessDigitalMerchandising"
                fallback={<Navigate to={'/products'} replace />}
              >
                <Suspense fallback={<Loading />}>
                  <DigitalMerchandising />
                </Suspense>
              </RenderIfAuthorized>
            }
          />
          <Route
            path="/digital-merchandising/flight/new"
            element={
              <RenderIfAuthorized
                requiredPermission="accessDigitalMerchandising"
                fallback={<Navigate to={'/products'} replace />}
              >
                <Suspense fallback={<Loading />}>
                  <GateJamCreateFlight />
                </Suspense>
              </RenderIfAuthorized>
            }
          />
          <Route
            path="/digital-merchandising/flight/:id"
            element={
              <RenderIfAuthorized
                requiredPermission="accessDigitalMerchandising"
                fallback={<Navigate to={'/products'} replace />}
              >
                <Suspense fallback={<Loading />}>
                  <FlightDetail />
                </Suspense>
              </RenderIfAuthorized>
            }
          />
        </Route>
        <Route
          path="/brand-theme-editor/:id"
          element={
            <RenderIfAuthorized
              requiredPermission="accessBrandPages"
              fallback={<Navigate to={'/products'} replace />}
            >
              <Suspense fallback={<Loading />}>
                <BrandThemeEditor />
              </Suspense>
            </RenderIfAuthorized>
          }
        />
      </Route>
    </Route>
  </Route>,
]);
