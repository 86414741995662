import type { FlightRateString } from '@jane/ad-manager/types';

import { FlightDetailSection } from './flightDetailSection';

type Props = {
  displaysOn: string;
  menuRowTitle?: string;
  model: FlightRateString;
  placements: string[];
};

const modelType = {
  CPM: 'Product group (CPM)',
  CPC: 'Individual products (CPC)',
  Flat: 'Priority placement',
};

export const TypeDetails = (
  props: Props & {
    displaysOn: string;
    model: FlightRateString;
    placements: string[];
  }
) => {
  const maybePlacement =
    props.placements.length > 0
      ? [{ name: 'Placement', value: props.placements.join(', ') }]
      : [];

  const maybeMenuRowTitle =
    props.model === 'CPM' && props.menuRowTitle
      ? [{ name: 'Menu Row Title', value: props.menuRowTitle }]
      : [];

  const rows: { name: string; value: string }[] = [
    {
      name: 'Type',
      value: modelType[props.model],
    },
    ...maybePlacement,
    ...maybeMenuRowTitle,
    { name: 'Displays On', value: props.displaysOn },
  ];

  return <FlightDetailSection headerLabel="Details" rows={[rows]} />;
};
