import { useState } from 'react';
import { useController } from 'react-hook-form';

import { Flex, Modal, Typography } from '@jane/shared/reefer';

import { AdBuilderSectionContainer } from '../adBuilderSectionContainer';
import type { FlatAdSubmissionForm } from '../useAdBuilderForm';
import { CategorySelector } from './categorySelector/categorySelector';
import { ProductSelector } from './productSelector/productSelector';
import { ProductTargetingModeSelector } from './productTargetingModeSelector';

export type SelectionModeOptions = 'automatic' | 'category' | 'manual';

export const AdProductsSection = () => {
  const {
    field: {
      onChange: onChangeProductCategories,
      value: productCategoriesCategories,
    },
  } = useController<FlatAdSubmissionForm, 'productCategories'>({
    name: 'productCategories',
  });
  const {
    field: { onChange: onChangeProductIds, value: productIds },
  } = useController<FlatAdSubmissionForm, 'productIds'>({ name: 'productIds' });

  const getInitialSelectionMode = (): SelectionModeOptions => {
    if (productCategoriesCategories.length > 0) {
      return 'category';
    } else if (productIds.length > 0) {
      return 'manual';
    } else {
      return 'automatic';
    }
  };
  const [selectionMode, setSelectionMode] = useState<SelectionModeOptions>(
    getInitialSelectionMode()
  );

  const onSetSelectionMode = (value: SelectionModeOptions) => {
    switch (value) {
      case 'automatic':
        onChangeProductCategories([]);
        onChangeProductIds([]);
        break;
      case 'category':
        onChangeProductIds([]);
        break;
      case 'manual':
        onChangeProductCategories([]);
        break;
    }
    setSelectionMode(value);
  };

  return (
    <AdBuilderSectionContainer data-testid="product-targeting-section">
      <Flex flexDirection="column">
        <Typography variant="header-bold" branded mb={24}>
          Products
        </Typography>
        <ProductTargetingModeSelector
          selectionMode={selectionMode}
          setSelectionMode={onSetSelectionMode}
        />
        {selectionMode !== 'automatic' && (
          <Modal.ContentDivider color="grays-light" />
        )}
        {selectionMode === 'category' && <CategorySelector />}
        {selectionMode === 'manual' && <ProductSelector />}
      </Flex>
    </AdBuilderSectionContainer>
  );
};
