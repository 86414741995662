import { parsePacificDate } from './time';

export interface ImageValidationParams {
  file: File;
  fileSizeErrorMessage: string;
  fileSizeValidator: (file: File) => boolean;
  fileTypeErrorMessage: string;
  fileTypeValidator: (file: File) => boolean;
  imageDimensionsErrorMessage: string;
  imageDimensionsValidator: (height: number, width: number) => boolean;
}

export const validators = {
  isHexValue: (value: string): boolean => {
    const validHexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
    return validHexColorRegex.test(value);
  },
  isDateRangeValid: (startDate?: string, endDate?: string): boolean => {
    if (!startDate || !endDate) return true;

    const startDateObj = parsePacificDate(startDate);
    const endEodTimeObj = parsePacificDate(endDate);

    return !startDateObj.isAfter(endEodTimeObj);
  },
  isValidImage: ({
    file,
    fileTypeValidator,
    fileTypeErrorMessage,
    fileSizeValidator,
    fileSizeErrorMessage,
    imageDimensionsValidator,
    imageDimensionsErrorMessage,
  }: ImageValidationParams): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!fileTypeValidator(file)) return resolve(fileTypeErrorMessage);
      if (!fileSizeValidator(file)) return resolve(fileSizeErrorMessage);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        const image = new Image();

        if (typeof dataURL == 'string') {
          image.src = dataURL;
          image.onload = () => {
            const { height, width } = image;
            const imageDimensionsError = !imageDimensionsValidator(
              height,
              width
            )
              ? imageDimensionsErrorMessage
              : '';
            resolve(imageDimensionsError);
          };
        } else {
          reject('File unreadable or empty');
        }
      };
    }),
};

export const validationMessages = {
  invalidStartDateRage: 'Start date should be earlier than the end date',
  invalidEndDateRage: 'End date should be later than the start date',
  invalidColorHexCode: 'Please enter a valid hex color code',
};
