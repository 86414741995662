import styled from '@emotion/styled';

import { useGetLatest } from '@jane/ad-manager/data-access';
import type { ProductBrand } from '@jane/ad-manager/types';
import { makeBrandThemeStub } from '@jane/ad-manager/util';
import { Loading } from '@jane/shared/reefer';

import { Error } from './error';
import { PreviewCard } from './previewCard';

const NonSuccess = styled.div(() => ({
  height: 300,
  position: 'relative',
}));

interface Props {
  brand: ProductBrand;
}
export const BrandThemesBody = ({ brand }: Props) => {
  const { data, isLoading, isError, isSuccess } = useGetLatest(brand.id);
  const brandTheme = data ? data.productBrandTheme : makeBrandThemeStub(brand);
  return (
    <>
      {isLoading && (
        <NonSuccess>
          <Loading />
        </NonSuccess>
      )}
      {isError && (
        <NonSuccess>
          <Error />
        </NonSuccess>
      )}
      {isSuccess && <PreviewCard brand={brand} brandTheme={brandTheme} />}
    </>
  );
};
