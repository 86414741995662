import { useEffect, useState } from 'react';

import type { Brand } from '@jane/catalog-cms/data-access';
import {
  useBrands,
  useInfiniteBrands,
  useSelectedBrands,
} from '@jane/catalog-cms/hooks';
import { Modal } from '@jane/shared/reefer';

import { SelectBrandInputModal } from '../SelectBrandInputModal/SelectBrandInputModal';
import { SelectedBrands } from '../SelectedBrands/SelectedBrands';

export const BrandPicker = () => {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [internalSelectedBrands, setInternalSelectedBrands] = useState<Brand[]>(
    []
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    data: brands,
    fetchNextPage,
    isFetchingNextPage,
    totalBrandCount,
  } = useInfiniteBrands({ perPage: 100, query });

  const {
    selectedBrandIds,
    updateSelectedBrands: updateSelectedBrandsQueryString,
  } = useSelectedBrands();

  const { data: selectedBrands } = useBrands({
    brandIds: selectedBrandIds,
  });

  // Keep selected brands as part of local state so it never gets set to undefined while query is fetching new brands.
  useEffect(() => {
    if (selectedBrands) {
      setInternalSelectedBrands(selectedBrands);
    }
  }, [selectedBrands]);

  const handleOnSelectChange = (selectedBrands: Brand[]) => {
    const selectedBrandIds = selectedBrands.map((brand) => {
      return brand.id;
    });

    setInternalSelectedBrands(selectedBrands);
    updateSelectedBrandsQueryString(selectedBrandIds);
  };

  const closeModal = () => {
    setQuery('');
    setOpen(false);
  };

  // Determines if user has only 1 brand available to them and sets state to disable the brand picker
  useEffect(() => {
    if (
      totalBrandCount === 1 &&
      selectedBrandIds.length === 0 &&
      query === '' &&
      internalSelectedBrands.length === 0
    ) {
      if (brands && brands[0]) {
        setInternalSelectedBrands([brands[0]]);
      }
      setDisabled(true);
    }
  }, [
    totalBrandCount,
    brands,
    selectedBrandIds.length,
    query,
    internalSelectedBrands.length,
  ]);

  return (
    <>
      <SelectedBrands
        onClick={() => setOpen(true)}
        selectedBrands={internalSelectedBrands}
        isLoading={!selectedBrands && internalSelectedBrands.length === 0}
        disabled={disabled}
      />
      <Modal
        appId="root"
        open={open}
        onRequestClose={closeModal}
        overlayClose={true}
      >
        <SelectBrandInputModal
          brands={brands || []}
          selectedBrands={internalSelectedBrands}
          onChange={handleOnSelectChange}
          onSearchChange={setQuery}
          isFetchingNextPage={isFetchingNextPage}
          onNextPage={fetchNextPage}
          onModalClose={closeModal}
        />
      </Modal>
    </>
  );
};
