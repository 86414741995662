import { Box, Flex, Typography } from '@jane/shared/reefer';

interface FlightDetailSectionProps {
  'data-testid'?: string;
  headerLabel: string;
  rows: FlightDetailsCell[][];
}
export interface FlightDetailsCell {
  name: string;
  value: string | JSX.Element;
}
export const FlightDetailSection = ({
  'data-testid': testId,
  headerLabel,
  rows,
}: FlightDetailSectionProps) => (
  <Box
    py={40}
    px={40}
    border="grays-light"
    borderRadius={'lg'}
    background={'grays-white'}
    data-testid={testId}
  >
    <Typography variant="header-bold">{headerLabel}</Typography>
    {rows.map((rowItems) => (
      <Box mt={24} key={rowItems.reduce((acc, { name }) => acc + name, '')}>
        <Flex>
          {rowItems.map(({ name, value }) => (
            <Flex grow flexDirection="column" key={name}>
              <Typography color="grays-mid" variant="caps">
                {name}
              </Typography>
              {value}
            </Flex>
          ))}
        </Flex>
      </Box>
    ))}
  </Box>
);
