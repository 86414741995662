import { useState } from 'react';

import { CategorySelectInputModal } from '@jane/shared-b2b/components';
import { ConfirmDiscardWrapper } from '@jane/shared/components';

import { PRODUCT_CATEGORIES } from '../categories';
import type { ProductCategory } from '../categories';

interface CategorySelectorModalProps {
  open: boolean;
  selectedCategories: ProductCategory[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCategories: React.Dispatch<
    React.SetStateAction<ProductCategory[]>
  >;
}

export const CategorySelectorModal = ({
  open,
  setOpen,
  selectedCategories,
  setSelectedCategories,
}: CategorySelectorModalProps) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  return (
    <ConfirmDiscardWrapper
      appId="root"
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      contentLabel="category select"
    >
      <CategorySelectInputModal
        categoryOptions={PRODUCT_CATEGORIES}
        onDirty={setIsDirty}
        selectedCategories={selectedCategories}
        onSave={(categories) => {
          setSelectedCategories(categories);
          setOpen(false);
        }}
      />
    </ConfirmDiscardWrapper>
  );
};
