import styled from '@emotion/styled';

import type { BoxProps, TypographyProps } from '@jane/shared/reefer';
import { Box, Flex, Typography } from '@jane/shared/reefer';

const fontColor: TypographyProps['color'] = 'grays-black';
const backgroundColor: BoxProps['background'] = 'grays-ultralight';

const StatisticsEntryGrid = styled.div<{ columns: number }>((props) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
  width: '100%',
}));

export interface StatisticsEntryProps {
  label: string;
  valueDisplay: string | number;
}

const StatisticsEntry = ({ label, valueDisplay }: StatisticsEntryProps) => (
  <Box>
    <Typography branded color={fontColor} variant="header-bold" as="div">
      {valueDisplay}
    </Typography>
    <Typography color={fontColor} variant="mini" as="h2">
      {label}
    </Typography>
  </Box>
);

const StatisticsListRow = ({
  entries,
}: {
  entries: StatisticsEntryProps[];
}) => (
  <StatisticsEntryGrid columns={entries.length}>
    {entries.map(({ label, valueDisplay }) => (
      <StatisticsEntry key={label} label={label} valueDisplay={valueDisplay} />
    ))}
  </StatisticsEntryGrid>
);

interface Props {
  entries: StatisticsEntryProps[];
  onTarget?: boolean;
}
export const StatisticsDisplay = ({ entries, onTarget }: Props) => (
  <Flex
    grow
    flexDirection="column"
    px={40}
    py={24}
    borderRadius="lg"
    background={backgroundColor}
    gap={24}
  >
    {onTarget && <Typography color="palm-dark">On target</Typography>}
    <StatisticsListRow entries={entries} />
  </Flex>
);
