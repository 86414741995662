import { uploadFileToS3 } from '@iheartjane/react-image-list';
import type { FileWithPath } from 'react-dropzone';

import { config } from '@jane/shared/config';

import type { ImageValidationParams } from './validators';
import { validators } from './validators';

const VALID_LOGO_FILE_TYPES = ['image/png', 'image/svg', 'image/jpeg'];
const MAX_LOGO_FILE_SIZE = 2_000_000;
const MIN_LOGO_DIMENSION = 200;
const LOGO_ASPECT_RATIO = 1;

const validateLogoFileType = (file: FileWithPath) =>
  VALID_LOGO_FILE_TYPES.includes(file.type);

const validateLogoFileSize = (file: FileWithPath) =>
  file.size <= MAX_LOGO_FILE_SIZE;

const validateLogoDimensions = (imgHeight: number, imgWidth: number) =>
  imgHeight >= MIN_LOGO_DIMENSION &&
  imgWidth >= MIN_LOGO_DIMENSION &&
  imgHeight / imgWidth === LOGO_ASPECT_RATIO;

const logoFileTypeError = 'Please upload a jpg, png or svg file';
const logoFileSizeError = 'Your upload exceeds maximum file size of 2MB';
const logoDimensionsError =
  'Your logo does not meet image dimension requirements';

export const uploadLogoImage = async (file: File) => {
  const logoParams: ImageValidationParams = {
    file,
    fileSizeErrorMessage: logoFileSizeError,
    fileSizeValidator: validateLogoFileSize,
    fileTypeErrorMessage: logoFileTypeError,
    fileTypeValidator: validateLogoFileType,
    imageDimensionsErrorMessage: logoDimensionsError,
    imageDimensionsValidator: validateLogoDimensions,
  };
  const error = await validators.isValidImage(logoParams);
  if (error) throw error;
  return await uploadFileToS3(
    `${config.brandServerUrl}/jam/file-upload`,
    file,
    {
      credentials: 'include',
    }
  );
};
